/* eslint-disable jsx-a11y/alt-text */
// eslint-disable-next-line jsx-a11y/alt-text
import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useRef,
} from 'react';
import PropTypes, {
  string,
  number,
  func,
  bool,
  oneOfType,
  object,
  arrayOf,
} from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CellFormatter from 'views/atoms/formatter/CellFormatter';
import CellContentWrapper from 'views/molecules/Cell/CellContentWrapper';
import {
  NUMBER_FIELDS,
  COLUMN_NOWRAP,
  FIELD_PREFIX,
  COLUMN_TEXT_ALIGN_RIGHT,
  COLUMN_NOWRAP_WITH_TOOLTIP,
  UNSYNCED_DISPLAY_VALUE,
  HIDE_SWITCH_BTN_MESSAGES,
} from 'domain/consts';
import classNames from 'classnames';
import * as FIELDS from 'domain/fields';
import * as DisplayItems from 'domain/settings/display-items';
import { getPrefix, getSuffix, getToday } from 'services/utils';
import { getFieldApiResponse } from 'domain/utils';
import { convertApiDetail, getLinkParams } from 'domain/responseUtils';
import TableContext from 'views/templates/TableStatisticsTemplate/components/table-context';
import screenTitleConfigs from 'services/common/screenTitleConfigs';
import { useSelector, shallowEqual } from 'react-redux';
import settingsSelector from 'store/settings/selectors';
import ExpandCellTable from 'views/pages/ad-management/components/ExpandCellTable';
import ValuesExpandCellTable from 'views/pages/ad-management/components/ValuesExpandCellTable';
import {
  INTERNAL,
  CV_ATTRIBUTE,
  AD_MANAGEMENT,
} from 'services/routes/constants';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';
import { reflectionTimeSelectors } from 'store/reflectiontime';
import moment from 'moment';
import {
  LINK_URL,
  MEDIA_SIDE_FINAL_URL,
  TITLE,
  TRANSITION_RATE,
} from 'domain/settings/display-items-ad-management';
import CopyToClipboard from 'views/atoms/CopyToClipboard';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import filterSelectors from 'store/filters/selectors';

const getPath = (metric, params) => {
  const pathes = {
    [DisplayItems.TRACKING_DATA]: `user_profile/${params.tracking_data}/${params.record_id}`,
  };
  return pathes[metric];
};

const CellDisplay = ({
  rowNumber,
  type,
  metric,
  value,
  params,
  maxHeight,
  onClick,
  showZeros,
  handleResizeColumn,
  isExpandCellTable,
  ebisViewPattern,
  syncViewPattern,
  hideSwitchBtnMessage,
  isDisplayCopyBtn,
  isExpand,
  onExpand,
  isLpoFlag,
  isIgnoneFormat,
}) => {
  const { t } = useTranslation();
  const refEl = useRef(null);
  let content = useMemo(
    () => (
      <CellFormatter
        metric={metric}
        value={value}
        showZeros={showZeros}
        useTranslation={t}
        ebisViewPattern={ebisViewPattern}
        syncViewPattern={syncViewPattern}
        isIgnoneFormat={isIgnoneFormat}
      />
    ),
    [
      t,
      metric,
      value,
      showZeros,
      ebisViewPattern,
      syncViewPattern,
      isIgnoneFormat,
    ]
  );

  const [isOneCellExpand, setOneCellExpand] = useState(false);
  const [isHide, setHide] = useState(true);

  const calculateCollapse = useCallback(() => {
    const id = `collapse-${metric}-${rowNumber}`;
    const el = document.getElementById(id);
    return el && el.getElementsByTagName('span')[0].offsetHeight > maxHeight;
  }, [rowNumber, maxHeight, metric]);

  const cellFomatter = useCallback(
    (val) => {
      return (
        <CellFormatter
          metric={metric}
          value={val}
          showZeros={showZeros}
          useTranslation={t}
          ebisViewPattern={ebisViewPattern}
          syncViewPattern={syncViewPattern}
          isIgnoneFormat={isIgnoneFormat}
        />
      );
    },
    [t, metric, showZeros, ebisViewPattern, syncViewPattern, isIgnoneFormat]
  );

  switch (type) {
    case 'linkInternal':
      content = (
        <ExternalTransitionWrapper
          link={getPath(metric, params)}
          type={INTERNAL}
          newTab={false}
          disable={false}
        >
          <div style={{ color: '#2347B5' }}>{content}</div>
        </ExternalTransitionWrapper>
      );
      break;

    case 'linkExternal':
      content = (
        <a href={value} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      );
      break;

    case 'viewDetail':
      content = (
        <Button
          size="sm"
          variant="link"
          className="p-0 border-0 text-left"
          onClick={onClick}
        >
          {content}
        </Button>
      );
      break;

    case 'collapse': {
      if (isExpandCellTable) {
        content = (
          <>
            {[TITLE, TRANSITION_RATE, LINK_URL].includes(metric) ? (
              <ValuesExpandCellTable
                values={value}
                metric={metric}
                rowNumber={rowNumber}
                handleResizeColumn={handleResizeColumn}
                isExpand={isExpand}
                onExpand={onExpand}
                cellFomatter={cellFomatter}
                isLpoFlag={isLpoFlag}
              />
            ) : (
              <ExpandCellTable
                value={value}
                metric={metric}
                rowNumber={rowNumber}
                handleResizeColumn={handleResizeColumn}
              />
            )}
          </>
        );
      } else {
        const isCollapse = calculateCollapse();
        content = (
          <>
            <div
              id={`collapse-${metric}-${rowNumber}`}
              style={{
                overflow: 'hidden',
                maxHeight: isOneCellExpand ? null : maxHeight,
              }}
            >
              <span className="d-inline-block">{content}</span>
            </div>
            {isCollapse && (
              <Button
                size="xs"
                variant="link"
                className="p-0 border-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setOneCellExpand(!isOneCellExpand);
                }}
              >
                {isOneCellExpand ? '省略して表示' : 'すべて表示'}
              </Button>
            )}
          </>
        );
      }
      break;
    }
    case 'imageView': {
      let newValue = value;
      if (!newValue) {
        newValue = 0;
      }
      const widthStyle = {
        width: `${newValue}%`,
      };
      content = (
        <>
          <div className="image-cell">
            <div className="value-style">{content}</div>
            <div style={{ width: '100%' }}>
              <span style={widthStyle} />
            </div>
          </div>
        </>
      );
      break;
    }
    default:
      break;
  }

  if (hideSwitchBtnMessage) {
    const HideSwitchBtn = (
      <Button
        size="xs"
        variant="link"
        className="p-0 border-0"
        onClick={(e) => {
          e.stopPropagation();
          setHide(!isHide);
        }}
      >
        {isHide ? hideSwitchBtnMessage[0] : hideSwitchBtnMessage[1]}
      </Button>
    );
    const overwrite = [HideSwitchBtn];
    if (isDisplayCopyBtn)
      overwrite.push(<CopyToClipboard className="copyBtn" value={value} />);
    overwrite.push(<br />);
    if (!isHide) overwrite.push(content);
    content = overwrite;
  }

  if (![...COLUMN_NOWRAP_WITH_TOOLTIP].includes(metric)) return content;

  return (
    <span ref={refEl}>
      <CellContentWrapper ref={refEl}>
        <>{content}</>
      </CellContentWrapper>
    </span>
  );
};

CellDisplay.propTypes = {
  rowNumber: number.isRequired,
  metric: string.isRequired,
  value: oneOfType([string, number]).isRequired,
  params: arrayOf(oneOfType(object)),
  type: string,
  maxHeight: number,
  onClick: func,
  showZeros: bool,
  handleResizeColumn: func.isRequired,
  isExpandCellTable: bool,
  syncViewPattern: string,
  ebisViewPattern: string,
  hideSwitchBtnMessage: arrayOf(string),
  isDisplayCopyBtn: bool,
  isExpand: bool,
  onExpand: func,
  isLpoFlag: bool,
  isIgnoneFormat: bool,
};

CellDisplay.defaultProps = {
  type: '',
  maxHeight: null,
  showZeros: false,
  params: [],
  onClick: () => {},
  isExpandCellTable: false,
  syncViewPattern: '0',
  ebisViewPattern: '0',
  hideSwitchBtnMessage: null,
  isDisplayCopyBtn: false,
  isExpand: false,
  onExpand: () => {},
  isLpoFlag: false,
  isIgnoneFormat: false,
};

const Cell = React.memo(
  React.forwardRef((props, ref) => {
    const { attrs } = useContext(TableContext);
    let {
      metric,
      options: { cellDisplay },
    } = props;

    const {
      options: {
        isArrayData,
        onClick,
        maxHeight,
        rowNumber,
        handleResizeColumn,
        isExpandCellTable,
        ebisViewPattern,
        syncViewPattern,
        priorityAxis,
        isIgnoneFormat = false,
      },
      data,
      width,
      copyBtnDisplayColumnName,
      toolTipMessages,
      hideBtnDisplayColumnName,
      isExpand,
      onExpand,
    } = props;
    const screenId = useSelector(settingsSelector.getPage, shallowEqual);
    const { showZeros = false, releaseDate = '' } = screenTitleConfigs[
      screenId
    ];
    const classNameByMetric = metric;
    const field = useMemo(() => getFieldApiResponse(metric), [metric]);
    const dataConvert = useMemo(() => convertApiDetail(data), [data]);
    const params = useMemo(() => getLinkParams(data, metric), [data, metric]);
    let isLpoFlag = false;

    const prefix = useMemo(
      () => getPrefix(metric, Object.values(FIELD_PREFIX)),
      [metric]
    );
    if (prefix) {
      const suffix = getSuffix(metric, Object.values(FIELD_PREFIX));
      metric =
        DisplayItems[suffix.toUpperCase()] || FIELDS[suffix.toUpperCase()]
          ? suffix
          : prefix;
    }
    const reflectionData = useSelector(
      reflectionTimeSelectors.getReflectionTimeData,
      shallowEqual
    );
    const filters = useSelector(filterSelectors.getSettings, shallowEqual);
    // Judgment process for displaying links that transition to the user profile screen.
    if (screenId === CV_ATTRIBUTE) {
      const subtag = moment(
        reflectionData.user_profile
          ? reflectionData.user_profile[0]?.distillery_subtag
          : '2021-12-18 00:00:00'
      ).format('YYYY-MM-DD HH:mm:ss');
      const date = getToday().add(-89, 'day').format('YYYY-MM-DD HH:mm:ss');
      if (
        metric === DisplayItems.TRACKING_DATA &&
        cellDisplay === 'linkInternal'
      ) {
        if (
          data.cv_access_time > subtag ||
          data.cv_access_time < releaseDate ||
          data.cv_access_time < date ||
          filters.cross_device
        ) {
          cellDisplay = '';
        }
      }
    }
    if (
      screenId === AD_MANAGEMENT &&
      [LINK_URL, TITLE, TRANSITION_RATE].includes(metric)
    ) {
      cellDisplay = 'collapse';
      isLpoFlag = data.lpo_flag;
    }

    const cls = useMemo(
      () =>
        classNames({
          'freeze-table__cell-content': true,
          [classNameByMetric]: true,
          'text-right':
            NUMBER_FIELDS.includes(metric) ||
            COLUMN_TEXT_ALIGN_RIGHT.includes(metric),
          'text-nowrap':
            COLUMN_NOWRAP.includes(metric) ||
            COLUMN_NOWRAP_WITH_TOOLTIP.includes(metric),
          'text-multi': isArrayData,
          'text-nowrap--has-tooltip': COLUMN_NOWRAP_WITH_TOOLTIP.includes(
            metric
          ),
        }),
      [classNameByMetric, metric, isArrayData]
    );

    let styles = {};
    if ((ref && ref.current) || width) {
      styles.width = width || `${ref.current.style.width}`;
    }
    if ('columns' in attrs && classNameByMetric in attrs.columns) {
      styles = { ...attrs.columns[classNameByMetric] };
    }
    let contentDisplay = [];

    const hideSwitchBtnMessage = hideBtnDisplayColumnName.includes(field.value)
      ? HIDE_SWITCH_BTN_MESSAGES[screenId][field.value]
      : null;

    if (isArrayData) {
      contentDisplay = dataConvert[field.value].map((item, index) => {
        let display = cellDisplay;
        // [取得中]表記ならリンクを無効にする。
        if (
          screenId === AD_MANAGEMENT &&
          ((metric === LINK_URL && item === UNSYNCED_DISPLAY_VALUE) ||
            (metric === TRANSITION_RATE && !item))
        ) {
          display = '';
        }
        return (
          <div
            className="text-item"
            style={{ height: attrs.itemsHeight?.[rowNumber]?.[index] || null }}
            key={rowNumber}
          >
            <CellDisplay
              rowNumber={rowNumber}
              type={display}
              metric={metric}
              value={item}
              params={params}
              onClick={onClick}
              showZeros={showZeros}
              handleResizeColumn={handleResizeColumn}
              isExpandCellTable={isExpandCellTable}
              screenId={screenId}
              isExpand={isExpand}
              onExpand={onExpand}
              isLpoFlag={isLpoFlag}
            />
          </div>
        );
      });
    } else {
      let display = cellDisplay;
      // [取得中]表記ならリンクを無効にする。
      if (
        screenId === AD_MANAGEMENT &&
        metric === MEDIA_SIDE_FINAL_URL &&
        dataConvert[field.value] === UNSYNCED_DISPLAY_VALUE
      ) {
        display = '';
      }
      if (
        screenId === AD_MANAGEMENT &&
        metric === TRANSITION_RATE &&
        !dataConvert[field.value]
      ) {
        display = '';
        dataConvert[field.value] = '';
      }
      contentDisplay.push(
        <CellDisplay
          key={rowNumber}
          rowNumber={rowNumber}
          type={display}
          metric={metric}
          value={dataConvert[field.value]}
          params={params}
          maxHeight={maxHeight}
          onClick={onClick}
          showZeros={showZeros}
          handleResizeColumn={handleResizeColumn}
          isExpandCellTable={isExpandCellTable}
          ebisViewPattern={ebisViewPattern}
          syncViewPattern={syncViewPattern}
          hideSwitchBtnMessage={hideSwitchBtnMessage}
          isDisplayCopyBtn={copyBtnDisplayColumnName.includes(field.value)}
          screenId={screenId}
          isExpand={isExpand}
          onExpand={onExpand}
          isLpoFlag={isLpoFlag}
          isIgnoneFormat={isIgnoneFormat}
        />
      );
    }

    let toolTip = toolTipMessages[dataConvert.rowId];
    if (toolTip) {
      toolTip = toolTip[field.value];
      if (toolTip) contentDisplay.push(<DragonBall>{toolTip}</DragonBall>);
    }

    return (
      <div style={styles} ref={ref} className={cls} axis={priorityAxis}>
        {contentDisplay}
      </div>
    );
  })
);

Cell.propTypes = {
  metric: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  copyBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  toolTipMessages: PropTypes.oneOfType([PropTypes.object]),
  hideBtnDisplayColumnName: PropTypes.oneOfType([PropTypes.array]),
  isExpand: bool,
  onExpand: func,
};

Cell.defaultProps = {
  options: {},
  data: {},
  width: null,
  copyBtnDisplayColumnName: [],
  toolTipMessages: {},
  hideBtnDisplayColumnName: [],
  isExpand: false,
  onExpand: () => {},
};

export default Cell;
