import {
  CALENDAR_DAY_FORMAT,
  API_DATE_FORMAT,
  MEASUREMENT_TYPE_REDIRECT,
  MEASUREMENT_TYPE_DIRECT,
  UNREGISTERED_DISPLAY_VALUE,
} from 'domain/consts';
import { formatNumber } from 'domain/utils';
import moment from 'moment';
import classNames from 'classnames';
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  object,
  func,
  oneOfType,
  objectOf,
  shape,
  oneOf,
  array,
  string,
  number,
} from 'prop-types';
import _ from 'lodash';
import * as field from 'domain/fields';
import adManagementRules from 'services/validations/adManagementRules';
import * as adConsts from 'domain/ad-management/consts';
import { getToday, communicationStatus, formatMessage } from 'services/utils';
import { APP_HELP_DIRECT_MEASUREMENT_SETTINGS } from 'services/routes/constants';
import EbisCheckbox from 'views/atoms/checkbox/EbisCheckbox';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import EbisSimpleDateRangePicker from 'views/molecules/calendar/EbisSimpleDateRangePicker';
import FormGroup from 'views/molecules/FormGroup';
import EbisStep from 'views/molecules/Step/EbisStep';
import EbisCollapse from 'views/atoms/Collapse/EbisCollapse';
import AdManagementDetailView from 'views/pages/ad-management/components/AdManagementDetailView';
import {
  FormGroupInput,
  FormGroupTextarea,
  FormGroupUpload,
  FormGroupRadio,
  FormGroupMultiUrl,
  FormGroupSelect,
} from 'views/pages/ad-management/components/forms';
import {
  setDefaultValueFormRegister,
  formatDataErrorForForm,
  formatDataErrorForLpoForm,
  isMeasureHost,
  checkFieldFormDisplay,
} from 'services/ad-management/adManagementService';
import {
  requiredValue,
  validAdUrl,
  maxLength,
  notContainSpaces,
  notContainControlCharacters,
  notEqualValue,
  isInteger,
  totalCheck,
} from 'services/validations/commonValidations';
import adErrorMessages from 'services/validations/adErrorMessages';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';
import useCheckPermissionMediaGroup from 'services/custom-hooks/useCheckPermissionMediaGroup';
import { FormContext } from 'views/molecules/Form';

const { IDLE, LOADING, SUCCEEDED, FAILED } = communicationStatus;
const LPO_URLS_LINE_COUNT_DEFAULT = 5;

function AdSettingsModal(props) {
  const {
    action,
    masterData,
    detailData,
    detailError,
    config,
    status,
    onHide,
    onSubmit,
    onFinish,
    onFetchMasterData,
    onSearchMasterData,
    onCreateMasterData,
    mode,
    lpupCount,
  } = props;

  const {
    isAgentUser,
    hasContractAgent,
    agencyName,
  } = useCheckPermissionAgency();
  const {
    hasPemissionMedia,
    hasPemissionAdgroup1,
    hasPemissionAdgroup2,
  } = useCheckPermissionMediaGroup();

  const { dispatch } = useContext(FormContext);

  const { agency, media, adGroup1, adGroup2, display } = masterData;
  const scrollbarWrapperRef = useRef(null);
  const isAdd = action === 'add';
  const [isSubmit, setSubmit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState({});

  const data = useMemo(() => {
    return isAdd && step === 1 ? {} : detailData;
  }, [isAdd, step, detailData]);

  const [isNormal, isListing, isYdn, isAutoRegistered, isLpo] = useMemo(() => {
    const isNormalAd =
      isAdd || data?.delivery_type === adConsts.DELIVERY_TYPE_UNKNOWN;
    const isListingAd =
      !isAdd && data?.ad_type === adConsts.AD_MANAGEMENT_MODE.LISTING;
    const isYdnAd = !isAdd && data?.ydn_flag;
    const isAutoRegisteredAd = !isAdd && data?.auto_registered === 't';
    const isLpoAd = mode === adConsts.AD_MANAGEMENT_MODE.LPO || data?.lpo_flag;

    return [isNormalAd, isListingAd, isYdnAd, isAutoRegisteredAd, isLpoAd];
  }, [isAdd, data, mode]);

  const newLpupCount = useMemo(() => {
    return isLpo && lpupCount ? lpupCount : LPO_URLS_LINE_COUNT_DEFAULT;
  }, [isLpo, lpupCount]);

  const fieldForm = useMemo(() => {
    return checkFieldFormDisplay(
      adConsts.FIELD_FORM,
      display,
      isListing,
      isYdn,
      hasContractAgent,
      isLpo
    );
  }, [display, isListing, isYdn, hasContractAgent, isLpo]);

  const defaultValue = useMemo(
    () =>
      setDefaultValueFormRegister(
        fieldForm,
        data,
        !isNormal && !isListing,
        isLpo,
        newLpupCount
      ),
    [fieldForm, data, isNormal, isListing, isLpo, newLpupCount]
  );

  const [valueField, setValueField] = useState(defaultValue);
  const [banner, setBanner] = useState(defaultValue[field.BANNER]);
  const [mediaRequired, setMediaRequired] = useState(false);
  const [mediaSelected, setMedia] = useState({});
  const [adGroup1Selected, setAdGroup1] = useState({});
  const [adGroup2Selected, setAdGroup2] = useState({});
  const [agencySelected, setAgency] = useState({});
  const [hasAvailablePeriod, setAvailablePeriod] = useState(false);
  const [baseCostType, setBaseCostType] = useState(field.BASE_CPC);

  useEffect(() => {
    if (isSubmit || (!isAdd && status === LOADING)) {
      setLoading(true);
    }
    if (!isSubmit && !isAdd && status === SUCCEEDED) {
      setLoading(false);
    } else if (isSubmit && status === SUCCEEDED) {
      setLoading(false);
      setSubmit(false);
      setStep(2);
    } else if (isSubmit && status === FAILED) {
      setLoading(false);
      setSubmit(false);
    }
  }, [onHide, status, isSubmit, isAdd]);

  useEffect(() => {
    if (_.isEmpty(data)) return;

    setAgency({ id: data.agency_id, name: data.agency_name });
    setMedia({ id: data.media_id, name: data.media_name });
    setAdGroup1({ id: data.ad_group1_id, name: data.ad_group1_name });
    setAdGroup2({ id: data.ad_group2_id, name: data.ad_group2_name });
  }, [data]);

  useEffect(() => {
    setValueField(defaultValue);
    setBanner(defaultValue[field.BANNER]);
    setBaseCostType(
      defaultValue[field.BASE_CPCONV] ? field.BASE_CPCONV : field.BASE_CPC
    );
    setAvailablePeriod(
      defaultValue[field.AD_START_DATE] && defaultValue[field.AD_END_DATE]
    );
  }, [defaultValue]);

  useEffect(() => {
    if (!isLpo) {
      setErrorMessage(formatDataErrorForForm(detailError, display));
    } else {
      setErrorMessage(formatDataErrorForLpoForm(detailError, display));
    }
  }, [detailError, display, isLpo]);

  useEffect(() => {
    setMediaRequired(!!isAgentUser || !_.isEmpty(agencySelected?.id));
  }, [isAgentUser, agencySelected]);

  const steps = adConsts.STEPS.map((item) => ({
    ...item,
    active: item.step === step,
  }));

  // LPO not cv cost
  const isAdCvCostMode = useMemo(() => {
    return (
      config.adCvCostMode !== adConsts.AD_CV_COST_MODE_NONE_VALUE &&
      mode !== adConsts.AD_MANAGEMENT_MODE.LPO &&
      !isLpo
    );
  }, [config.adCvCostMode, mode, isLpo]);

  const isDisabledButtonSubmit = useMemo(() => {
    return (
      _.isEqual(defaultValue, valueField) &&
      _.isEqual(defaultValue[field.BANNER], banner)
    );
  }, [valueField, defaultValue, banner]);

  const isGetMasterByAgency = useMemo(() => {
    return (
      hasContractAgent &&
      !isLoading &&
      step === 1 &&
      !_.isEmpty(agencySelected?.id)
    );
  }, [hasContractAgent, isLoading, step, agencySelected]);

  const agencyIdSelected = useMemo(
    () =>
      isAgentUser || _.isEmpty(agencySelected) ? undefined : agencySelected.id,
    [isAgentUser, agencySelected]
  );

  const adStartDateValue = valueField[field.AD_START_DATE];
  const adEndDateValue = valueField[field.AD_END_DATE];
  const adDuration = useMemo(() => {
    const today = getToday().format(CALENDAR_DAY_FORMAT);
    return {
      start: moment(adStartDateValue || today),
      end: moment(adEndDateValue || today),
    };
  }, [adStartDateValue, adEndDateValue]);

  const handleSetFieldValue = useCallback(
    (field, value) => {
      setValueField({
        ...valueField,
        [field]: value,
      });
      setErrorMessage({
        ...errorMessage,
        [field]: undefined,
      });
    },
    [errorMessage, valueField]
  );

  const handleCreateMasterData = useCallback(
    (value, name) => {
      onCreateMasterData(value, name, agencyIdSelected);
    },
    [onCreateMasterData, agencyIdSelected]
  );

  const onToggleBaseCostType = useCallback(
    (name, value) => {
      if (name === field.BASE_COST_TYPE) {
        setBaseCostType(value);
        handleSetFieldValue(
          value === field.BASE_CPC ? field.BASE_CPCONV : field.BASE_CPC,
          ''
        );
      } else {
        handleSetFieldValue(name, value);
      }
    },
    [handleSetFieldValue]
  );

  const onToggleAdDate = useCallback(() => {
    const enabelPeriod = !hasAvailablePeriod;
    const start = enabelPeriod ? adDuration.start.format(API_DATE_FORMAT) : '';
    const end = enabelPeriod ? adDuration.end.format(API_DATE_FORMAT) : '';
    setAvailablePeriod(enabelPeriod);
    setValueField({
      ...valueField,
      [field.AD_START_DATE]: start,
      [field.AD_END_DATE]: end,
    });
    setErrorMessage({
      ...errorMessage,
      [field.AD_START_DATE]: undefined,
      [field.AD_END_DATE]: undefined,
    });
  }, [
    hasAvailablePeriod,
    adDuration.start,
    adDuration.end,
    valueField,
    errorMessage,
  ]);

  const onAdDateChanged = useCallback(
    (date) => {
      const { start, end } = date.period;
      setValueField({
        ...valueField,
        [field.AD_START_DATE]: start.format(CALENDAR_DAY_FORMAT),
        [field.AD_END_DATE]: end.format(CALENDAR_DAY_FORMAT),
      });
      setErrorMessage({
        ...errorMessage,
        [field.AD_START_DATE]: undefined,
        [field.AD_END_DATE]: undefined,
      });
    },
    [valueField, errorMessage]
  );

  const onFileUploadAccepted = useCallback((files) => {
    const [file] = files;
    setBanner(file);
  }, []);

  const onFileUploadClear = useCallback(() => {
    setBanner('');
    setErrorMessage({
      ...errorMessage,
      [field.BANNER]: undefined,
    });
  }, [errorMessage]);

  const validateLinkToUrl = useCallback(
    (url, label) => {
      if (isAutoRegistered) return '';
      if (valueField[field.MEASUREMENT_TYPE] !== MEASUREMENT_TYPE_DIRECT)
        return '';

      if (!isMeasureHost(url, config.measurementHosts)) {
        return formatMessage(
          adErrorMessages.MSG_TOOLTIP_URL_IN_HOST_CONFIG_CHECK,
          { label }
        );
      }
      if (!isListing && url.indexOf('#') >= 0) {
        return formatMessage(
          adErrorMessages.MSG_TOOLTIP_AD_URL_EXIT_HASHTAG_CHECK,
          { label }
        );
      }

      return '';
    },
    [isListing, config.measurementHosts, valueField]
  );

  const validateMeasurementType = useCallback(
    (measurementType) => {
      let error = '';
      const { label } = fieldForm[field.MEASUREMENT_TYPE];
      const isDeliveryAd = [
        adConsts.DELIVERY_TYPE_TAD,
        adConsts.DELIVERY_TYPE_TDV,
      ].includes(data?.delivery_type);
      if (isDeliveryAd && measurementType === MEASUREMENT_TYPE_DIRECT) {
        error = `無効な値が入力されています。${label}は空欄にするか、「リダイレクト」を入力してください。（空欄の場合は自動的に「リダイレクト」で登録されます）`;
      }

      return error;
    },
    [data, fieldForm]
  );

  const validateMultiUrlTitle = useCallback(
    (items) => {
      if (
        items.length === 1 &&
        'url' in items[0] &&
        'transition_rate' in items[0]
      ) {
        return [];
      }
      const newItems =
        items.filter?.((item) => {
          return Object.keys(item).length > 0;
        }) ?? [];

      if (isLpo) {
        let count = 0;
        newItems.forEach(item => {
          if (item.url !== "") {
            count += 1;
          }
        })
        if (count < 2) {
          return [
            {
              url: adErrorMessages.MSG_TOOLTIP_MINIMUM_URLS_CHECK,
              page_title: '',
              transition_rate: '',
            },
          ];
        }
      }

      const totalNum = items.reduce((sum, keys) => {
        const num =
          !Object.keys(keys).length || !('transition_rate' in keys)
            ? 0
            : Number(keys.transition_rate);
        return sum + num;
      }, 0);

      const errors = isAutoRegistered
        ? []
        : newItems.map((item, index) => {
            const urlRules = [
              notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
              validAdUrl([
                adErrorMessages.MSG_TOOLTIP_AD_URL_FORMAT_CHECK,
                adErrorMessages.MSG_TOOLTIP_AD_URL_NOT_QUESTION_MARK_CHECK,
              ]),
              maxLength(
                adConsts.URL_MAX,
                adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK
              ),
            ];

            const pageTitleRule = [
              notContainSpaces(adErrorMessages.MSG_TOOLTIP_SPTAB_CHECK),
              notContainControlCharacters(
                adErrorMessages.MSG_TOOLTIP_CONTROL_CHARACTER_CHECK
              ),
              maxLength(
                adConsts.PAGE_TITLE_MAX,
                adErrorMessages.MSG_TOOLTIP_MAX_LENGTH_CHECK
              ),
            ];

            const rateRule = [
              isInteger(adErrorMessages.MSG_TOOLTIP_NUMBER_INTEGER),
            ];
            if (!isLpo) {
              urlRules.push(
                requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK)
              );
            }

            if (
              isLpo &&
              (item.url || item.page_title || item.transition_rate)
            ) {
              urlRules.push(
                requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK)
              );
              pageTitleRule.push(
                requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK)
              );
              rateRule.push(
                requiredValue(adErrorMessages.MSG_TOOLTIP_EMPTY_CHECK)
              );
            }
            let errorUrls = urlRules
              .map((rule) => rule({ label: 'URL', value: item.url }))
              .filter((rule) => !_.isEmpty(rule));

            const errorPageTitles = pageTitleRule
              .map((rule) =>
                rule({ label: 'タイトル', value: item.page_title })
              )
              .filter((rule) => !_.isEmpty(rule));

            let errorRate = !isLpo
              ? []
              : rateRule
                  .map((rule) => {
                    return rule({
                      label: '遷移割合',
                      value: String(item.transition_rate),
                    });
                  })
                  .filter((rule) => !_.isEmpty(rule));

            if (_.isEmpty(errorUrls)) {
              errorUrls = [validateLinkToUrl(item.url, 'URL')];
            }
            // Error messages for rate total checks are displayed in the first column only
            if (_.isEmpty(errorRate) && isLpo && index === 0) {
              const rule = totalCheck(
                adErrorMessages.MSG_TOOLTIP_TOTAL_RATE_CHECK,
                totalNum
              );
              errorRate = [rule({ label: '遷移割合' })];
            }

            return {
              url: errorUrls[0],
              page_title: errorPageTitles[0],
              transition_rate: errorRate[0],
            };
          });

      const countError = errors.filter(
        (error) => error.url || error.page_title || error.transition_rate
      );

      return countError.length > 0 ? errors : [];
    },
    [validateLinkToUrl, isLpo]
  );

  const validateBaseCpconv = useCallback(
    (value) => {
      if (value && !isAdCvCostMode && !isListing && !isLpo) {
        const { label } = fieldForm[field.BASE_CPCONV];
        return formatMessage(
          adErrorMessages.MSG_TOOLTIP_BASE_CPCONV_VALID_CHECK,
          { label }
        );
      }

      return '';
    },
    [isAdCvCostMode, isListing, isLpo, fieldForm]
  );

  const validate = useCallback(
    (formData) => {
      const dataValidate = { ...formData };
      if (!isAdd) {
        delete dataValidate[field.AD_ID];
      }
      if (isAutoRegistered) {
        delete dataValidate[field.URL];
      }

      const ruleValidate = { ...adManagementRules };
      if (mediaRequired) {
        const ruleMedia = [
          requiredValue(),
          ...(ruleValidate[field.MEDIA_ID] || []),
        ];
        ruleValidate[field.MEDIA_ID] = ruleMedia;
      }

      const error = _.chain(dataValidate)
        .mapValues((value, key) => ({
          label: fieldForm[key].label,
          value,
          name: key,
        }))
        .mapValues((value, key) => {
          const rules = ruleValidate[key];
          if (_.isEmpty(rules)) return [];

          const errors = rules
            .map((rule) => rule(value))
            .filter((rule) => !_.isEmpty(rule));
          return errors;
        })
        .pickBy((errors) => !_.isEmpty(errors))
        .mapValues((errors) => errors[0])
        .value();

      if (isLpo || isYdn) {
        error[field.URLS] = validateMultiUrlTitle(
          valueField[field.URLS],
          isLpo
        );
      } else if (_.isEmpty(error[field.URL])) {
        error[field.URL] = validateLinkToUrl(
          valueField[field.URL],
          fieldForm[field.URL].label
        );
      }
      if (_.isEmpty(error[field.MEASUREMENT_TYPE])) {
        error[field.MEASUREMENT_TYPE] = validateMeasurementType(
          valueField[field.MEASUREMENT_TYPE]
        );
      }
      if (_.isEmpty(error[field.BASE_CPCONV])) {
        error[field.BASE_CPCONV] = validateBaseCpconv(
          valueField[field.BASE_CPCONV]
        );
      }

      return error;
    },
    [
      isYdn,
      isAdd,
      fieldForm,
      valueField,
      validateLinkToUrl,
      validateMeasurementType,
      validateMultiUrlTitle,
      validateBaseCpconv,
      mediaRequired,
    ]
  );

  const validateAndSubmit = useCallback(() => {
    dispatch({ type: 'setSubmit', payload: true });
    // FIXME lpo
    const validateResults = validate({ ...valueField, [field.BANNER]: banner });
    const errors = { ...errorMessage, ...validateResults };
    const error = Object.keys(errors)
      .filter((key) => !_.isEmpty(errors[key]))
      .reduce((acc, key) => ({ ...acc, [key]: errors[key] }), {});
    setErrorMessage(error);

    if (_.isEmpty(error)) {
      const dataPost = { ...valueField, [field.BANNER]: banner };
      if (!isAdd) {
        if (isLpo) {
          dataPost.type = 'lpo';
        } else {
          dataPost.type = data?.ad_type || null;
        }
      }
      if (isListing && !isYdn) {
        dataPost.urls = [
          {
            url: dataPost.url,
            page_title: dataPost.page_title,
            terminal_type: '',
          },
        ];
      }

      onSubmit(dataPost, isAdd);
      setSubmit(true);
    }
  }, [
    dispatch,
    isAdd,
    isListing,
    isYdn,
    onSubmit,
    valueField,
    data,
    banner,
    validate,
    errorMessage,
  ]);

  const onSelectMedia = useCallback(
    (itemSelected) => {
      handleSetFieldValue(field.MEDIA_ID, itemSelected.id);
      setMedia(itemSelected);
    },
    [handleSetFieldValue]
  );
  const onClearMedia = useCallback(() => {
    handleSetFieldValue(field.MEDIA_ID, '');
    setMedia({});
  }, [handleSetFieldValue]);
  const onSearchMedia = useCallback(
    (value, selected) => {
      onSearchMasterData(field.MEDIA_ID, value, selected, agencyIdSelected);
    },
    [onSearchMasterData, agencyIdSelected]
  );

  const onSelectAdGroup1 = useCallback(
    (itemSelected) => {
      handleSetFieldValue(field.AD_GROUP1_ID, itemSelected.id);
      setAdGroup1(itemSelected);
    },
    [handleSetFieldValue]
  );
  const onClearAdGroup1 = useCallback(() => {
    handleSetFieldValue(field.AD_GROUP1_ID, '');
    setAdGroup1({});
  }, [handleSetFieldValue]);
  const onSearchAdGroup1 = useCallback(
    (value, selected) => {
      onSearchMasterData(field.AD_GROUP1_ID, value, selected, agencyIdSelected);
    },
    [onSearchMasterData, agencyIdSelected]
  );

  const onSelectAdGroup2 = useCallback(
    (itemSelected) => {
      handleSetFieldValue(field.AD_GROUP2_ID, itemSelected.id);
      setAdGroup2(itemSelected);
    },
    [handleSetFieldValue]
  );
  const onClearAdGroup2 = useCallback(() => {
    handleSetFieldValue(field.AD_GROUP2_ID, '');
    setAdGroup2({});
  }, [handleSetFieldValue]);
  const onSearchAdGroup2 = useCallback(
    (value, selected) => {
      onSearchMasterData(field.AD_GROUP2_ID, value, selected, agencyIdSelected);
    },
    [onSearchMasterData, agencyIdSelected]
  );

  const onSelectAgency = useCallback(
    (itemSelected) => {
      handleSetFieldValue(field.AGENCY_ID, itemSelected.id);
      setAgency(itemSelected);
    },
    [handleSetFieldValue]
  );
  const onClearAgency = useCallback(() => {
    handleSetFieldValue(field.AGENCY_ID, '');
    setAgency({});

    // reset media field
    setErrorMessage({
      ...errorMessage,
      [field.MEDIA_ID]: undefined,
    });
    onFetchMasterData();
  }, [errorMessage, handleSetFieldValue, onFetchMasterData]);
  const onSearchAgency = useCallback(
    (value) => {
      onSearchMasterData(field.AGENCY_ID, value);
    },
    [onSearchMasterData]
  );

  useEffect(() => {
    if (!isGetMasterByAgency) return;

    onFetchMasterData(agencySelected.id);
  }, [agencySelected, isGetMasterByAgency, onFetchMasterData]);

  const bodyClass = classNames({
    'scrollbar-wrapper': true,
    'd-flex': isLoading,
    'align-items-center': isLoading,
    'ad-id-error': !!errorMessage[field.AD_ID],
    'modal-body--loading': isLoading,
  });

  const getUrls = (value) => {
    // Initial value at registration returns an array
    if (value === '') {
      const initUrls = [];
      for (let i = 0; i < newLpupCount; i += 1) {
        initUrls.push({ url: '', page_title: '', transition_rate: '' });
      }
      return initUrls;
    }

    return value;
  };

  return (
    <Modal show centered size="w800" dialogClassName="ad-settings-modal">
      <Modal.Header className="justify-content-start">
        <Modal.Title style={{ marginRight: 10 }}>
          {adConsts.MODAL_TITLE[action]}
        </Modal.Title>
        <EbisStep steps={steps} />
      </Modal.Header>
      <Modal.Body ref={scrollbarWrapperRef} className={bodyClass}>
        <DataSyncLoader isLoading={isLoading}>
          <ScrollbarWrapper
            ref={scrollbarWrapperRef}
            maxContent={532}
            alignScroll={10}
          >
            {step === 2 ? (
              <AdManagementDetailView data={data} configDisplay={display} />
            ) : (
              <>
                <FormGroupInput
                  variant="ad-id"
                  required={false}
                  disabled={!isAdd}
                  label={fieldForm[field.AD_ID].label}
                  note={`半角英数字・ハイフン・アンダーバーのみ、${adConsts.AD_ID_MAX}文字以内`}
                  tooltip="任意の広告ID を入力してください例）g0001,g0002"
                  name={field.AD_ID}
                  value={valueField[field.AD_ID]}
                  style={{ width: '250px' }}
                  error={errorMessage[field.AD_ID]}
                  onChange={handleSetFieldValue}
                />
                <FormGroupInput
                  required
                  label={fieldForm[field.KEYWORD].label}
                  note={`${adConsts.AD_KEYWORD_MAX}文字以内`}
                  tooltip={
                    <>
                      登録広告を識別していただくための任意の名前を入力してください
                      <br />
                      ※日本語入力可
                    </>
                  }
                  name={field.KEYWORD}
                  value={valueField[field.KEYWORD]}
                  error={errorMessage[field.KEYWORD]}
                  onChange={handleSetFieldValue}
                />
                {isNormal &&
                  !isListing &&
                  !isAutoRegistered &&
                  mode !== adConsts.AD_MANAGEMENT_MODE.LPO &&
                  !isLpo && (
                    <FormGroupRadio
                      required={false}
                      label={fieldForm[field.MEASUREMENT_TYPE].label}
                      name={field.MEASUREMENT_TYPE}
                      value={valueField[field.MEASUREMENT_TYPE]}
                      items={[
                        {
                          label: 'ダイレクト（推奨）',
                          value: MEASUREMENT_TYPE_DIRECT,
                          disabled: false,
                        },
                        {
                          label: 'リダイレクト',
                          value: MEASUREMENT_TYPE_REDIRECT,
                          disabled: false,
                        },
                      ]}
                      error={errorMessage[field.MEASUREMENT_TYPE]}
                      note={
                        <>
                          ※ダイレクト計測の設定方法・注意点は
                          <a
                            href={APP_HELP_DIRECT_MEASUREMENT_SETTINGS}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            こちら
                          </a>
                          をご確認ください
                        </>
                      }
                      tooltip={
                        <>
                          計測方式を選択してください。
                          <br />
                          ダイレクト方式は広告からリンク先URLへ遷移する方式です。
                          <br />
                          リダイレクト方式は広告からのリダイレクトサーバを経由してリンク先URLへ遷移する方式です
                        </>
                      }
                      onChange={handleSetFieldValue}
                    />
                  )}
                {mode === adConsts.AD_MANAGEMENT_MODE.LPO || isLpo ? (
                  <>
                    <FormGroupMultiUrl
                      required
                      label={fieldForm[field.URLS].label}
                      name={field.URLS}
                      items={getUrls(valueField[field.URLS])}
                      errors={errorMessage[field.URLS]}
                      onChange={handleSetFieldValue}
                      mode={mode}
                      tooltip={
                        <>
                          LPO広告でABテストをしたいリンク先を2つ以上設定してください。遷移割合は合計100%になるよう入力してください。
                        </>
                      }
                      isLpo={isLpo}
                    />
                  </>
                ) : (
                  <>
                    {isYdn
                      ? !isAutoRegistered && (
                          <FormGroupMultiUrl
                            required
                            label={fieldForm[field.URLS].label}
                            name={field.URLS}
                            items={valueField[field.URLS]}
                            errors={errorMessage[field.URLS]}
                            onChange={handleSetFieldValue}
                            mode={mode}
                          />
                        )
                      : !isAutoRegistered && (
                          <>
                            <FormGroupInput
                              required
                              label={fieldForm[field.URL].label}
                              note={`${formatNumber(adConsts.URL_MAX)}文字以内`}
                              name={field.URL}
                              value={valueField[field.URL]}
                              error={errorMessage[field.URL]}
                              placeholder="https://"
                              onChange={handleSetFieldValue}
                            />
                            <FormGroupInput
                              label={fieldForm[field.PAGE_TITLE].label}
                              name={field.PAGE_TITLE}
                              value={valueField[field.PAGE_TITLE]}
                              error={errorMessage[field.PAGE_TITLE]}
                              onChange={handleSetFieldValue}
                            />
                          </>
                        )}
                  </>
                )}
                {hasContractAgent && (
                  <FormGroupSelect
                    isLoading={agency.isLoading}
                    label={fieldForm[field.AGENCY_ID].label}
                    name={field.AGENCY_ID}
                    items={agency.list}
                    value={agencySelected}
                    error={errorMessage[field.AGENCY_ID]}
                    tooltip="設定した担当代理店にて閲覧・編集・削除ができるようになります。分析画面への反映まで最大3分ほどかかります。"
                    onSearch={onSearchAgency}
                    onSelect={onSelectAgency}
                    onClear={onClearAgency}
                  />
                )}
                {isAgentUser && (
                  <FormGroup label={adConsts.FIELD_FORM[field.AGENCY_ID].label}>
                    {agencyName}
                  </FormGroup>
                )}
                <FormGroupSelect
                  isLoading={media.isLoading}
                  required={mediaRequired}
                  label={fieldForm[field.MEDIA_ID].label}
                  name={field.MEDIA_ID}
                  items={media.list}
                  value={mediaSelected}
                  error={errorMessage[field.MEDIA_ID]}
                  tooltip="媒体種別管理で登録した、媒体種別が選択可能となります"
                  createStatus={media.status}
                  createRules={[
                    requiredValue(),
                    notContainSpaces(),
                    notContainControlCharacters(),
                    maxLength(adConsts.MAX_LENGTH_MEDIA_NAME),
                    notEqualValue([UNREGISTERED_DISPLAY_VALUE]),
                  ]}
                  createItem={media.item}
                  createError={media.error}
                  onSearch={onSearchMedia}
                  onSelect={onSelectMedia}
                  onClear={onClearMedia}
                  onCreate={hasPemissionMedia && handleCreateMasterData}
                />
                <FormGroupSelect
                  isLoading={adGroup1.isLoading}
                  label={fieldForm[field.AD_GROUP1_ID].label}
                  name={field.AD_GROUP1_ID}
                  items={adGroup1.list}
                  value={adGroup1Selected}
                  error={errorMessage[field.AD_GROUP1_ID]}
                  tooltip={`${
                    fieldForm[field.AD_GROUP1_ID].label
                  }管理で登録した広告グループが選択可能となります`}
                  createStatus={adGroup1.status}
                  createRules={[
                    requiredValue(),
                    notContainSpaces(),
                    notContainControlCharacters(),
                    maxLength(adConsts.MAX_LENGTH_AD_GROUP_NAME),
                    notEqualValue([UNREGISTERED_DISPLAY_VALUE]),
                  ]}
                  createItem={adGroup1.item}
                  createError={adGroup1.error}
                  onSearch={onSearchAdGroup1}
                  onSelect={onSelectAdGroup1}
                  onClear={onClearAdGroup1}
                  onCreate={hasPemissionAdgroup1 && handleCreateMasterData}
                />
                <FormGroupSelect
                  isLoading={adGroup2.isLoading}
                  label={fieldForm[field.AD_GROUP2_ID].label}
                  name={field.AD_GROUP2_ID}
                  items={adGroup2.list}
                  value={adGroup2Selected}
                  error={errorMessage[field.AD_GROUP2_ID]}
                  tooltip={`${
                    fieldForm[field.AD_GROUP2_ID].label
                  }管理で登録した広告グループが選択可能となります`}
                  createStatus={adGroup2.status}
                  createRules={[
                    requiredValue(),
                    notContainSpaces(),
                    notContainControlCharacters(),
                    maxLength(adConsts.MAX_LENGTH_AD_GROUP_NAME),
                    notEqualValue([UNREGISTERED_DISPLAY_VALUE]),
                  ]}
                  createItem={adGroup2.item}
                  createError={adGroup2.error}
                  onSearch={onSearchAdGroup2}
                  onSelect={onSelectAdGroup2}
                  onClear={onClearAdGroup2}
                  onCreate={hasPemissionAdgroup2 && handleCreateMasterData}
                />
                <FormGroupTextarea
                  required={false}
                  label={fieldForm[field.AD_NOTE].label}
                  note={`${adConsts.AD_NOTE_MAX}文字以内`}
                  name={field.AD_NOTE}
                  value={valueField[field.AD_NOTE]}
                  error={errorMessage[field.AD_NOTE]}
                  onChange={handleSetFieldValue}
                />

                <EbisCollapse title="詳細設定の表示">
                  <div>
                    <FormGroup
                      required={false}
                      label="出稿期間"
                      tooltip="出稿期間を指定することで、登録した期間中にクリック/コンバージョンが計測されていない場合も、分析メニューの画面に広告の詳細が表示されます"
                    >
                      <div className="form-period d-flex align-items-center">
                        <EbisCheckbox
                          changeSelectBoxValue={onToggleAdDate}
                          text="指定する"
                          checked={hasAvailablePeriod}
                        />
                        <EbisSimpleDateRangePicker
                          drop="right"
                          disabled={!hasAvailablePeriod}
                          onChange={onAdDateChanged}
                          period={adDuration}
                          error={
                            errorMessage[field.AD_START_DATE] ||
                            errorMessage[field.AD_END_DATE]
                          }
                        />
                      </div>
                    </FormGroup>
                    {!isAutoRegistered && (
                      <>
                        {isListing ? (
                          <FormGroupInput
                            type="number"
                            style={{ width: 100 }}
                            label={fieldForm[field.BASE_CPC].label}
                            icon={<span style={{ marginRight: 7 }}>¥</span>}
                            placeholder=""
                            name={field.BASE_CPC}
                            value={valueField[field.BASE_CPC]}
                            error={errorMessage[field.BASE_CPC]}
                            tooltip={
                              <>
                                {fieldForm[field.BASE_CPC].label}
                                を登録しておけば、広告経由の訪問者が１回クリックする度に、広告コスト欄が自動的に計算されます
                              </>
                            }
                            onChange={handleSetFieldValue}
                          />
                        ) : (
                          <FormGroupRadio
                            required={false}
                            label="単価"
                            name={field.BASE_COST_TYPE}
                            value={baseCostType}
                            items={[
                              {
                                label: 'クリック単価',
                                value: field.BASE_CPC,
                                disabled: false,
                                input: {
                                  name: field.BASE_CPC,
                                  value:
                                    baseCostType === field.BASE_CPC
                                      ? valueField[field.BASE_CPC]
                                      : '',
                                  error: errorMessage[field.BASE_CPC],
                                },
                              },
                              {
                                label: 'CV単価',
                                value: field.BASE_CPCONV,
                                disabled: !isAdCvCostMode,
                                input: {
                                  name: field.BASE_CPCONV,
                                  value:
                                    baseCostType === field.BASE_CPCONV
                                      ? valueField[field.BASE_CPCONV]
                                      : '',
                                  error: errorMessage[field.BASE_CPCONV],
                                },
                              },
                            ]}
                            tooltip={
                              isLpo ? (
                                <>
                                  単価を設定することで、クリックが発生する度に、広告コストが自動的に算出されます。
                                </>
                              ) : (
                                <>
                                  単価を設定することで、クリックもしくはコンバージョンが、発生する度に、広告コストが自動的に算出されます。
                                  <br />
                                  ※CV単価を利用する場合は[システム設定CV単価の設定]を変更してください
                                </>
                              )
                            }
                            onChange={onToggleBaseCostType}
                            isLpo={isLpo}
                          />
                        )}
                      </>
                    )}
                    <FormGroupTextarea
                      required={false}
                      label={fieldForm[field.TEXT_DATA].label}
                      note={`${adConsts.TEXT_DATA_MAX}文字以内`}
                      tooltip="テキスト広告に利用した文章や、メルマガで使用した文章などが登録可能です"
                      name={field.TEXT_DATA}
                      value={valueField[field.TEXT_DATA]}
                      error={errorMessage[field.TEXT_DATA]}
                      onChange={handleSetFieldValue}
                    />
                    <FormGroupUpload
                      required={false}
                      label={fieldForm[field.BANNER].label}
                      error={errorMessage[field.BANNER]}
                      note={`${adConsts.BANNER_MAX_MEGABYTES}MB以下、拡張子（${adConsts.BANNER_ACCEPT_EXTENSION}）のみ`}
                      value={banner?.name ?? banner}
                      onFileUploadAccepted={onFileUploadAccepted}
                      onClear={onFileUploadClear}
                    />
                  </div>
                </EbisCollapse>
              </>
            )}
          </ScrollbarWrapper>
        </DataSyncLoader>
      </Modal.Body>
      <Modal.Footer>
        {step === 1 ? (
          <>
            <Button
              variant="link"
              size="sm"
              disabled={isSubmit && isLoading}
              onClick={onHide}
            >
              キャンセル
            </Button>
            <Button
              type="button"
              variant="secondary"
              size="sm"
              disabled={isDisabledButtonSubmit || isLoading}
              onClick={validateAndSubmit}
            >
              OK
            </Button>
          </>
        ) : (
          <Button
            size="sm"
            variant="link"
            onClick={() => {
              onHide();
              onFinish();
            }}
          >
            閉じる
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

AdSettingsModal.propTypes = {
  config: oneOfType([object]).isRequired,
  detailData: oneOfType([object]),
  detailError: oneOfType([array]),
  onHide: func,
  onSubmit: func,
  onFinish: func,
  masterData: objectOf(
    shape({
      agency: oneOfType([object]),
      media: oneOfType([object]),
      adGroup1: oneOfType([object]),
      adGroup2: oneOfType([object]),
    })
  ).isRequired,
  status: oneOf([IDLE, LOADING, SUCCEEDED, FAILED]).isRequired,
  action: oneOf(['add', 'edit']).isRequired,
  /**
   * @param {string} value
   * @param {agency|media|ad_group1|ad_group2} type
   */
  onFetchMasterData: func,
  onSearchMasterData: func,
  onCreateMasterData: func,
  mode: string,
  lpupCount: number,
};

AdSettingsModal.defaultProps = {
  detailData: {},
  detailError: [],
  onSubmit: () => {},
  onHide: () => {},
  onFinish: () => {},
  onFetchMasterData: () => {},
  onSearchMasterData: () => {},
  onCreateMasterData: () => {},
  mode: '',
  lpupCount: null,
};

export default AdSettingsModal;
