const GET_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/GET';
const SET_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/SET';
const UPDATE_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/UPDATE';
const DELETE_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/DELETE';

const CHECK_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/check/GET';
const SET_COOKIE_PROGRAM_SETTINGS_CHECK_STATUS =
  'system-setting/setcookie/check/SET';
const SET_CHECKING_COOKIE_PROGRAM_SETTINGS =
  'system-setting/setcookie/check/CHECKING';

const SET_STATUS_COOKIE_PROGRAM_SETTINGS = 'system-setting/setcookie/STATUS';
const SET_STATUS_COOKIE_PROGRAM_CHECKING =
  'system-setting/setcookie/check/STATUS';
const GET_DATA_SETTING_BASIC = 'system-setting/GET_DATA_SETTING_BASIC';
const SET_DATA_SETTING_BASIC = 'system-setting/SET_DATA_SETTING_BASIC';
const SET_ERROR_SETTING_BASIC = 'system-setting/SET_ERROR_SETTING_BASIC';
const SET_STATUS_SETTING_BASIC = 'system-setting/SET_STATUS_SETTING_BASIC';
const UPDATE_DATA_SETTING_BASIC = 'system-setting/UPDATE_DATA_SETTING_BASIC';
const UPDATE_DATA_SETTING_BASIC_FAILED =
  'system-setting/UPDATE_DATA_SETTING_BASIC_FAILED';
const UPDATE_DATA_SETTING_COOKIE_PROGRAM_FAILED =
  'system-setting/UPDATE_DATA_SETTING_COOKIE_PROGRAM_FAILED';

const GET_CROSS_DOMAIN_LIST = 'system-setting/GET_CROSS_DOMAIN_LIST';
const SET_CROSS_DOMAIN_LIST = 'system-setting/SET_CROSS_DOMAIN_LIST';

const GET_DATA_MEASUREMENT_SETTING =
  'system-setting/GET_DATA_MEASUREMENT_SETTING';
const SET_DATA_MEASUREMENT_SETTING =
  'system-setting/SET_DATA_MEASUREMENT_SETTING';
const SET_ERROR_MEASUREMENT_SETTING =
  'system-setting/SET_ERROR_MEASUREMENT_SETTING';
const SET_STATUS_MEASUREMENT_SETTING =
  'system-setting/SET_STATUS_MEASUREMENT_SETTING';
const UPDATE_MEASUREMENT_SETTING = 'system-setting/UPDATE_MEASUREMENT_SETTING';
const UPDATE_MEASUREMENT_SETTING_FAILED =
  'system-setting/UPDATE_MEASUREMENT_SETTING_FAILED';

const CHANGE_TAB = 'system-setting/CHANGE_TAB';

const CLEAN_STATES = 'system-setting/CLEAN_STATES';

const GET_DATA_OTHER_SETTING = 'system-setting/GET_DATA_OTHER_SETTING';
const SET_STATUS_OTHER_SETTING = 'system-setting/SET_STATUS_OTHER_SETTING';
const SET_DATA_OTHER_SETTING = 'system-setting/SET_DATA_OTHER_SETTING';
const UPDATE_DATA_OTHER_SETTING = 'system-setting/UPDATE_DATA_OTHER_SETTING';
const UPDATE_DATA_OTHER_SETTING_FAILED =
  'system-setting/UPDATE_DATA_OTHER_SETTING_FAILED';

const GET_DATA_DNS_SETTING = 'system-setting/GET_DATA_DNS_SETTING';
const SET_DATA_DNS_ADD_SETTING = 'system-setting/SET_DATA_DNS_ADD_SETTING';
const SET_DATA_DNS_SETTING = 'system-setting/SET_DATA_DNS_SETTING';
const SET_ERROR_DNS_SETTING = 'system-setting/SET_ERROR_DNS_SETTING';
const SET_STATUS_DNS_SETTING = 'system-setting/SET_STATUS_DNS_SETTING';
const ADD_DATA_DNS_SETTING = 'system-setting/ADD_DATA_DNS_SETTING';
const ADD_DATA_DNS_SETTING_FAILED =
  'system-setting/ADD_DATA_DNS_SETTING_FAILED';
const DELETE_DATA_DNS_SETTING = 'system-setting/DELETE_DATA_DNS_SETTING';
const SET_STATUS_SCREEN_COMPLETE = 'system-setting/SET_STATUS_SCREEN_COMPLETE';

export default {
  GET_COOKIE_PROGRAM_SETTINGS,
  SET_COOKIE_PROGRAM_SETTINGS,
  UPDATE_COOKIE_PROGRAM_SETTINGS,
  DELETE_COOKIE_PROGRAM_SETTINGS,
  CHECK_COOKIE_PROGRAM_SETTINGS,
  SET_COOKIE_PROGRAM_SETTINGS_CHECK_STATUS,
  SET_CHECKING_COOKIE_PROGRAM_SETTINGS,
  SET_STATUS_COOKIE_PROGRAM_SETTINGS,
  SET_STATUS_COOKIE_PROGRAM_CHECKING,
  GET_DATA_SETTING_BASIC,
  SET_DATA_SETTING_BASIC,
  SET_ERROR_SETTING_BASIC,
  SET_STATUS_SETTING_BASIC,
  UPDATE_DATA_SETTING_BASIC,
  CHANGE_TAB,
  CLEAN_STATES,
  GET_CROSS_DOMAIN_LIST,
  SET_CROSS_DOMAIN_LIST,
  UPDATE_DATA_SETTING_BASIC_FAILED,
  UPDATE_DATA_SETTING_COOKIE_PROGRAM_FAILED,
  GET_DATA_MEASUREMENT_SETTING,
  SET_DATA_MEASUREMENT_SETTING,
  SET_ERROR_MEASUREMENT_SETTING,
  SET_STATUS_MEASUREMENT_SETTING,
  UPDATE_MEASUREMENT_SETTING,
  UPDATE_MEASUREMENT_SETTING_FAILED,
  GET_DATA_OTHER_SETTING,
  SET_STATUS_OTHER_SETTING,
  SET_DATA_OTHER_SETTING,
  UPDATE_DATA_OTHER_SETTING,
  UPDATE_DATA_OTHER_SETTING_FAILED,
  GET_DATA_DNS_SETTING,
  SET_DATA_DNS_SETTING,
  SET_DATA_DNS_ADD_SETTING,
  SET_ERROR_DNS_SETTING,
  SET_STATUS_DNS_SETTING,
  ADD_DATA_DNS_SETTING,
  ADD_DATA_DNS_SETTING_FAILED,
  DELETE_DATA_DNS_SETTING,
  SET_STATUS_SCREEN_COMPLETE,
};
