import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  INFO_FORM_BASIC_SETTING as FORM,
  HOST_NAME,
  EMERGENCY_URL,
  TRAFFIC_CONTROL_FLAG,
  ITP_FLAG,
  CROSS_DOMAIN_FLAG,
  CROSS_DOMAIN_LIST,
} from 'domain/system-setting/consts';
import { renderTextByStatus } from 'services/system-setting/systemSettingServices';
import useCheckPermissionCapi from 'services/custom-hooks/useCheckPermissionCapi';
import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';
import useCheckPermissionSystemSetting from 'services/custom-hooks/useCheckPermissionSystemSetting';
import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import FormGroup from 'views/molecules/FormGroup/FormGroup';
import BasicSettingModal from './BasicSettingModal';

function BasicSettingContainer() {
  const { isLoading, data } = useSelector(
    systemSettingSelectors.getStatesBasicSetting
  );
  const { isAllowedSettingBasic } = useCheckPermissionSystemSetting();
  const { isCapiSingleContract } = useCheckPermissionCapi();
  const dispatch = useDispatch();
  const [isShowSettingModal, setShowSettingModal] = useState(false);
  const onOpenSettingModal = () => setShowSettingModal(true);
  const onHideSettingModal = () => setShowSettingModal(false);

  useEffect(() => {
    dispatch(systemSettingActions.getBasicSetting());
  }, [dispatch]);

  return (
    <DataSyncLoader isLoading={isLoading}>
      <div className="basic-setting">
        <FormGroup label={FORM[HOST_NAME].label} required>
          <div className="system-setting__pre-wrap">
            {data?.[HOST_NAME] ? data?.[HOST_NAME].replaceAll(',', '\r\n') : ''}
          </div>
        </FormGroup>
        {!isCapiSingleContract && (
          <FormGroup label={FORM[EMERGENCY_URL].label}>
            <div>
              {data?.[HOST_NAME] && data?.[HOST_NAME].length > 0
                ? data[HOST_NAME].split(',')[0]
                : ''}
            </div>
          </FormGroup>
        )}
        {isAllowedSettingBasic && !isCapiSingleContract && (
          <FormGroup label={FORM[TRAFFIC_CONTROL_FLAG].label}>
            <div>{renderTextByStatus(data[TRAFFIC_CONTROL_FLAG])}</div>
          </FormGroup>
        )}
        {!isCapiSingleContract && (
          <FormGroup
            label={FORM[ITP_FLAG].label}
            tooltip={FORM[ITP_FLAG].tooltip}
          >
            <div>{renderTextByStatus(data[ITP_FLAG])}</div>
          </FormGroup>
        )}
        <FormGroup
          label={FORM[CROSS_DOMAIN_FLAG].label}
          tooltip={FORM[CROSS_DOMAIN_FLAG].tooltip}
        >
          <div>{renderTextByStatus(data?.cross_domain_flag)}</div>
        </FormGroup>
        {data?.cross_domain_flag && (
          <FormGroup
            label={FORM[CROSS_DOMAIN_LIST].label}
            tooltip={FORM[CROSS_DOMAIN_LIST].tooltip}
          >
            <div className="system-setting__pre-wrap">
              {data?.[CROSS_DOMAIN_LIST]
                ? data[CROSS_DOMAIN_LIST].replaceAll(',', '\r\n')
                : ''}
            </div>
          </FormGroup>
        )}
        <Button
          size="sm"
          variant="secondary"
          onClick={onOpenSettingModal}
          disabled={isLoading}
        >
          編集
        </Button>
        {isShowSettingModal && (
          <BasicSettingModal
            onHide={onHideSettingModal}
            isShow={isShowSettingModal}
            data={data}
            isAllowedSettingBasic={isAllowedSettingBasic}
            isCapiSingleContract={isCapiSingleContract}
          />
        )}
      </div>
    </DataSyncLoader>
  );
}

export default BasicSettingContainer;
