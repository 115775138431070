import {
  MEASUREMENT_TYPE_REDIRECT,
  UNSYNCED_DISPLAY_VALUE,
} from 'domain/consts';
import {
  AD_DATE,
  AD_PASTE_URL,
  AD_URL,
  BANNER,
  QR_CODE,
  AD_GROUP1_ID,
  AD_GROUP1_NAME,
  AD_GROUP2_ID,
  AD_GROUP2_NAME,
  AD_NOTE,
  MEASUREMENT_TYPE,
  BASE_CPCONV,
  BASE_CPC,
} from 'domain/fields';
import { convertApiDetail } from 'domain/responseUtils';
import { formatValueForTable, getFieldApiResponse } from 'domain/utils';
import { number, object, objectOf, oneOfType, string, array } from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { URL_REDIRECT_NOT_RECOMMENDED } from 'services/routes/constants';
import {
  FIELD_DETAIL,
  UNREGISTERED_DISPLAY_VALUE,
} from 'domain/ad-management/consts';
import { checkFieldDetailDisplay } from 'services/ad-management/adManagementService';
import CopyToClipboard from 'views/atoms/CopyToClipboard';
import QRCode from 'views/molecules/QRCode';
import TableViewDetail from 'views/organism/table-view-detail/TableViewDetail';
import useCheckPermissionAgency from 'services/custom-hooks/useCheckPermissionAgency';

const CustomRow = ({ columns, rows }) => {
  const rowSpan = rows.length;
  return (
    <td colSpan={2} className="table-view-detail__custom-three-columns">
      {rows.map((item, index) => (
        <tr>
          {columns.map((column) => (
            <td rowSpan={index === 0 && column === 'title' ? rowSpan : null}>
              {item[column]}
            </td>
          ))}
        </tr>
      ))}
    </td>
  );
};

CustomRow.propTypes = {
  columns: oneOfType([array]),
  rows: oneOfType([array]),
};

CustomRow.defaultProps = {
  columns: [],
  rows: [],
};

const CustomJoinRow = ({ columns, rows }) => {
  return (
    <>
      {rows.map((item) => (
        <>
          {columns.map((column) => (
            <td>{item[column]}</td>
          ))}
        </>
      ))}
    </>
  );
};

CustomJoinRow.propTypes = {
  columns: oneOfType([array]),
  rows: oneOfType([array]),
};

CustomJoinRow.defaultProps = {
  columns: [],
  rows: [],
};

function AdManagementDetailView(props) {
  const { t } = useTranslation();
  const { data, configDisplay } = props;
  const { auto_registered: autoRegistered = 'f' } = data;
  const { hasContractAgent, isAgentUser } = useCheckPermissionAgency();

  const fieldsDisplay = useMemo(() => {
    return checkFieldDetailDisplay(
      {
        ...FIELD_DETAIL,
        [AD_GROUP1_NAME]: configDisplay[AD_GROUP1_ID],
        [AD_GROUP2_NAME]: configDisplay[AD_GROUP2_ID],
        [AD_NOTE]: configDisplay[AD_NOTE],
      },
      data,
      hasContractAgent || isAgentUser
    );
  }, [data, configDisplay, hasContractAgent, isAgentUser]);

  const details = useMemo(() => {
    const note = {
      qrCode: '',
      measurementType: '',
    };

    if (data.measurement_type === MEASUREMENT_TYPE_REDIRECT && !data.lpo_flag) {
      note.qrCode =
        '※リダイレクト計測はアドエビスの契約有効時のみリンク先への遷移が可能です。契約終了した場合リンク切れとなりますので、QRコードご利用時はご注意ください';
      note.measurementType = (
        <div className="str-note">
          ※リダイレクト計測は非推奨です。ご利用の場合は
          <a
            href={URL_REDIRECT_NOT_RECOMMENDED}
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          をご覧ください
        </div>
      );
    }

    return fieldsDisplay
      .filter((fieldDisplay) => {
        if (autoRegistered !== 't') {
          return true;
        }
        const { name } = fieldDisplay;
        return ![QR_CODE, BASE_CPCONV, BASE_CPC, AD_PASTE_URL].includes(name);
      })
      .map((fieldDisplay) => {
        const { name, title } = fieldDisplay;
        switch (name) {
          case AD_URL: {
            const isYdn = data?.ydn_flag;
            const hasDevice = data[name].some((item) => item.device);
            if (!hasDevice && !isYdn && !data.lpo_flag) {
              const { url } = data[name][0];
              return {
                title,
                value:
                  url === UNSYNCED_DISPLAY_VALUE ? (
                    <div className="break-line str-wrap">{url}</div>
                  ) : (
                    <div className="break-line str-wrap">
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {url}
                      </a>
                    </div>
                  ),
              };
            }

            let columns = ['title', 'device', 'url'];

            const rows = data[name].map((item) => {
              let newTitle = '';
              if (!isYdn && !data.lpo_flag) {
                newTitle = title;
              } else if (data.lpo_flag) {
                newTitle = <div> リンク先URL/タイトル/遷移割合 </div>;
              } else {
                newTitle = (
                  <div style={{ lineHeight: '16px' }}>
                    リンク先
                    <br />
                    URL/タイトル
                  </div>
                );
              }
              if (data.lpo_flag) {
                columns = ['title', 'url'];
                return {
                  title: newTitle,
                  url: (
                    <div className="break-line str-wrap">
                      {item.url.map((value, index) => {
                        return (
                          <>
                            <a
                              href={value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {value}
                            </a>
                            <div>{item.page_title[index]}</div>
                            <div>{item.transition_rate[index]}%</div>
                            {item.url.length > index + 1 && (
                              <br className="new-line" />
                            )}
                          </>
                        );
                      })}
                    </div>
                  ),
                };
              }

              return {
                title: newTitle,
                device: <div className="break-line">{item.device}</div>,
                url: (
                  <div className="break-line str-wrap">
                    {item.url === UNSYNCED_DISPLAY_VALUE ? (
                      <>{item.url}</>
                    ) : (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.url}
                      </a>
                    )}
                    {isYdn && (
                      <div style={{ marginTop: -4 }}>
                        {item.page_title || UNREGISTERED_DISPLAY_VALUE}
                      </div>
                    )}
                  </div>
                ),
              };
            });
            if (data.lpo_flag) {
              return {
                custom: <CustomJoinRow columns={columns} rows={rows} />,
              };
            }
            return {
              custom: <CustomRow columns={columns} rows={rows} />,
            };
          }
          case AD_PASTE_URL: {
            const isLPO = data?.lpo_flag === true && !isEmpty(data?.lpo_type);
            const url = (
              <div className="break-line str-wrap">
                <span>{data[name].paste_url}</span>
                <CopyToClipboard value={data[name].paste_url} />
              </div>
            );
            if (isLPO) {
              return  {
                title,
                value: url,
              }
            }

            const rows = [
              {
                title,
                type: (
                  <div className="break-line" key="通常">
                    通常
                  </div>
                ),
                url,
              },
              {
                title,
                type: (
                  <div className="break-line" key="Google広告/YSS用">
                    Google広告/YSS用
                  </div>
                ),
                url: note.measurementType || (
                  <div className="break-line str-wrap">
                    <span>{data[name].paste_url_parallel}</span>
                    <CopyToClipboard value={data[name].paste_url_parallel} />
                  </div>
                ),
              }
            ];

            return {
              custom: (
                <CustomRow columns={['title', 'type', 'url']} rows={rows} />
              ),
            };
          }
          case MEASUREMENT_TYPE: {
            return {
              title,
              value:
                data[name] === MEASUREMENT_TYPE_REDIRECT
                  ? 'リダイレクト'
                  : 'ダイレクト（推奨）',
            };
          }
          case BANNER: {
            return {
              title,
              value: data[name] ? (
                <img src={data[name]} alt="banner" />
              ) : (
                UNREGISTERED_DISPLAY_VALUE
              ),
            };
          }
          case AD_DATE: {
            let value = UNREGISTERED_DISPLAY_VALUE;
            if (data.ad_start_date && data.ad_end_date) {
              const adDate = `${data.ad_start_date} ~ ${data.ad_end_date}`;
              value = formatValueForTable(adDate, name, {
                useTranslation: t,
              });
            }

            return { title, value };
          }
          case QR_CODE: {
            return {
              title,
              value: (
                <QRCode url={data[AD_PASTE_URL].paste_url} note={note.qrCode} />
              ),
            };
          }
          default: {
            const dataConvert = convertApiDetail(data);
            const field = getFieldApiResponse(name);
            const value = formatValueForTable(dataConvert[field.value], name, {
              useTranslation: t,
            });

            return {
              title,
              value: value || UNREGISTERED_DISPLAY_VALUE,
            };
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsDisplay, data]);

  return <TableViewDetail data={details} />;
}

AdManagementDetailView.propTypes = {
  data: objectOf(oneOfType([string, number])).isRequired,
  configDisplay: oneOfType([object]),
};

AdManagementDetailView.defaultProps = {
  configDisplay: {},
};

export default AdManagementDetailView;
