import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bool, shape, string } from 'prop-types';
import { Table } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';
import {
  MAX_ROW_DNS_SETTING,
  NS_SETTING,
  STATUS_DNS_SETTING,
  DNS_AVAILABLE_STATUS,
  DNS_ERROR_STATUS,
  DNS_WARMUP_STATUS,
  DNS_SETTING_STATUS,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4,
  CNAME_SETTING,
} from 'domain/system-setting/consts';
import { CNAME_DOMAIN_FIELD, NS_DOMAIN_FIELD } from 'domain/fields';

import systemSettingActions from 'store/system-setting/actions';
import systemSettingSelectors from 'store/system-setting/selectors';

import { getHeaderDnsTable } from 'services/system-setting/systemSettingServices';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import { SYSTEM_SETTING_DNS } from 'services/routes/constants';

import DataSyncLoader from 'views/atoms/loader/DataSyncLoader';
import EbisCollapseCustom from 'views/atoms/Collapse/EbisCollapseCustom';
import DeleteConfirmModal from 'views/molecules/modal/DeleteConfirmModal';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';
import ControlBar from 'views/molecules/control-bar';
import GridTableTemplate from 'views/templates/GridTableTemplate';
import useModalController from 'services/custom-hooks/useModalController';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import DnsSettingModal from './DnsSettingModal';

const MODAL_IDS = {
  ADD: 'add',
  CONFIRM_DELETE: 'confirm_delete',
  ERROR: 'error',
};

const RenderStatusText = ({ status, statusText, statusTextExtra, tab }) => {
  return (
    <div>
      <div className="dns-status">
        {status === DNS_ERROR_STATUS && (
          <img src={iconCautionYellow} alt="icon-caution" />
        )}
        {status === DNS_AVAILABLE_STATUS && <i className="fas fa-check" />}
        <span>{statusText}</span>
        {[DNS_ERROR_STATUS, DNS_WARMUP_STATUS, DNS_SETTING_STATUS].includes(
          status
        ) && <DragonBall>{STATUS_DNS_SETTING[status][tab]}</DragonBall>}
      </div>
      {statusTextExtra && (
        <div className="dns-status__extra">{statusTextExtra}</div>
      )}
    </div>
  );
};

RenderStatusText.propTypes = {
  status: string.isRequired,
  statusText: string.isRequired,
  statusTextExtra: string.isRequired,
  tab: string.isRequired,
};

const RenderRowTable = ({ row, isScreenComplete }) => {
  return (
    <EbisCollapseCustom
      title={
        row.ns_fqdn || (row.asp_flag ? `${row.cname_fqdn}*` : row.cname_fqdn)
      }
      isAlwayOpenExpand={isScreenComplete && !row.asp_flag}
      isShowIconExpand={!isScreenComplete && !row.asp_flag}
    >
      {row.asp_flag ? (
        <span />
      ) : (
        <div>
          <Table striped bordered hover className="dns-expand-table">
            <thead>
              <tr>
                <th> </th>
                <th>ホスト名</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>計測用</td>
                <td>{row.ns_fqdn || row.cname_fqdn}</td>
                <td>
                  {row.ebis_fqdn ? (
                    `${row.ebis_fqdn}.`
                  ) : (
                    <>
                      {row.name_server_1}.
                      <br /> {row.name_server_2}.
                      <br />
                      {row.name_server_3}.
                      <br />
                      {row.name_server_4}.
                    </>
                  )}
                </td>
              </tr>
              {!row.emails && (
                <tr>
                  <td className="dns-row-record">
                    証明書審査用
                    {row.ns_fqdn && (
                      <>
                        <br />
                        （弊社設定用）
                        <DragonBall>
                          NSの場合は弊社で設定を行うため、お客様のDNSに「証明書審査用」の登録は不要です。
                        </DragonBall>
                      </>
                    )}
                  </td>
                  <td className="blur-column">
                    {row.acm_record_name || row.record_name}
                  </td>
                  <td className="blur-column">
                    {row.acm_record_value || row.record_value}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {row.emails && (
            <div className="dns-contain-email">
              <div>審査メール送付先</div>
              <div>{row.emails}</div>
            </div>
          )}
        </div>
      )}
    </EbisCollapseCustom>
  );
};

RenderRowTable.propTypes = {
  row: shape({}).isRequired,
  isScreenComplete: bool.isRequired,
};

function DnsSettingContainer({ tab }) {
  const { openModal, closeModals, isOpen } = useModalController({
    ids: Object.values(MODAL_IDS),
  });

  const {
    isLoading,
    data,
    dnsAdd,
    isEmptyHostName,
    isDisabledRegister,
    isScreenComplete,
  } = useSelector(systemSettingSelectors.getStatesDnsSetting);

  const dispatch = useDispatch();

  const [listIdsDelete, setListIdsDelete] = useState([]);
  const [listItemDelete, setListItemDelete] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isAddEmailMode, setIsAddEmailMode] = useState(false);
  const [error, setError] = useState({ title: '', message: '' });

  const handleHideModal = () => {
    closeModals();
    setIsAddEmailMode(false);
  };

  const handleSelectRow = (ids) => setListIdsDelete(() => ids);

  const handleOpenSettings = useCallback(() => {
    openModal(MODAL_IDS.ADD);
  }, [openModal]);

  const handleConfirmDelete = useCallback(() => {
    const list = data.reduce((listResult, currentItem, currentIndex) => {
      if (listIdsDelete.includes(currentItem.etld1)) {
        return [
          ...listResult,
          {
            value: data[currentIndex].ns_fqdn || data[currentIndex].cname_fqdn,
            order: currentIndex,
          },
        ];
      }
      return listResult;
    }, []);
    setListItemDelete(list);
    openModal(MODAL_IDS.CONFIRM_DELETE);
  }, [data, listIdsDelete, openModal]);

  const getList = useCallback(() => {
    dispatch(systemSettingActions.getDnsSetting(tab));
  }, [tab, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleConfirmModal = () => {
    closeModals();
    getList();
  };

  const handleShowErrorModal = useCallback(
    (title, message) => {
      setError({ title, message });
      openModal(MODAL_IDS.ERROR);
    },
    [openModal]
  );

  const handleResponseDelete = ({ errors }) => {
    setListIdsDelete([]);
    closeModals();
    setLoadingDelete(false);

    if (isEmpty(errors)) {
      setListIdsDelete([]);
    } else {
      const message = getErrorMessageByCode(errors[0], messageError);
      handleShowErrorModal('データ更新に失敗しました', message);
    }
  };

  const handleSubmitDelete = () => {
    setLoadingDelete(true);
    dispatch(
      systemSettingActions.deleteDnsSetting(listIdsDelete, handleResponseDelete)
    );
  };

  const rows = useMemo(() => {
    return (
      data?.length > 0 &&
      data.reduce((prevItem, currentItem) => {
        return [
          ...prevItem,
          {
            etld1: currentItem.etld1,
            [currentItem.cname_fqdn && !isScreenComplete
              ? CNAME_DOMAIN_FIELD
              : NS_DOMAIN_FIELD]: (
              <RenderRowTable
                row={currentItem}
                isScreenComplete={isScreenComplete}
                isAddEmailMode={isAddEmailMode}
              />
            ),
            status_text: (
              <RenderStatusText
                status={currentItem.status}
                statusTextExtra={currentItem.status_text_extra}
                statusText={currentItem.status_text}
                tab={tab}
              />
            ),
            isAllowSelectRow: true,
          },
        ];
      }, [])
    );
  }, [data, isAddEmailMode, isScreenComplete, tab]);

  return (
    <div className="dns-setting h-100">
      <DataSyncLoader isLoading={isLoading}>
        {isEmptyHostName ? (
          <div className="dns-setting__empty-hostname">
            <Link to="/system-setting/basic" className="txt-decoration-none">
              計測対象サイト
            </Link>
            を設定していただくことで、 <br />
            計測用DNSの設定が行えます。
          </div>
        ) : (
          <GridTableTemplate
            isLoading={isLoading}
            idFreedHead="freeze-head-area-dns"
            pageId={SYSTEM_SETTING_DNS}
            variant={SYSTEM_SETTING_DNS}
            header={getHeaderDnsTable(isScreenComplete, tab)}
            rows={rows || []}
            onSelectRow={handleSelectRow}
            onEditRow={handleOpenSettings}
            isTableCenter
            tableControl={[
              <div
                className={
                  isScreenComplete
                    ? 'dns-setting-table__complete'
                    : 'dns-setting-table'
                }
              >
                <div className="dns-setting__left">
                  <div className="dns-setting__title">
                    {tab === NS_SETTING ? 'NSドメイン' : 'CNAMEドメイン'}
                  </div>
                  {!isScreenComplete && (
                    <ControlBar
                      page={SYSTEM_SETTING_DNS}
                      currentItems={data?.length || 0}
                      maximumItems={MAX_ROW_DNS_SETTING}
                      checkedItemsNum={isLoading ? 0 : listIdsDelete.length}
                      setShowConfirmModal={handleConfirmDelete}
                      handleShowSettingModal={handleOpenSettings}
                      disabledRegister={
                        isLoading || isDisabledRegister || dnsAdd.length < 1
                      }
                    />
                  )}
                </div>
                {isScreenComplete && (
                  <div>
                    <div>登録を受け付けました。</div>
                    <div className="dns-setting-table__complete-note">
                      <div className="dns-setting-table__complete-bold">
                        登録はまだ完了していません
                      </div>
                      {isAddEmailMode ? (
                        <div>
                          設定を完了させるためには、以下2つの設定が必要です。
                          <br />
                          なお設定期間は、疎通確認の時間も含めた期限となっております、期限切れの数時間前にはDNSの登録を行ってください。
                          <br />
                          <br />
                          ・ドメインの「計測用」レコードをお客様のDNSに登録
                          <br />
                          ・Amazon Web
                          Servicesから送信された証明書審査メールの案内に沿ったお手続き
                          <div className="dns-note">
                            ※メール審査の概要については、詳しくは
                            <a
                              href={
                                APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4
                              }
                              target="__blank"
                            >
                              こちら
                            </a>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div>
                            {tab === NS_SETTING
                              ? '設定を完了させるためには、ドメインの「計測用」レコードをお客様のDNSに登録する必要があります。'
                              : '設定を完了させるためには、ドメインの「計測用」レコード（CNAMEの場合は「証明書審査用」も併せて）をお客様のDNSに登録する必要があります。'}
                            <br />
                            なお設定期間は、疎通確認の時間も含めた期限となっております、期限切れの数時間前にはDNSの登録を行ってください。
                          </div>
                          {tab === CNAME_SETTING && (
                            <div className="mt-10">
                              ※[*]がついているASPドメインの場合、上記作業は必要ありません。
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
                {!isScreenComplete && (
                  <div className="paginations">計{data?.length || 0}件</div>
                )}
              </div>,
            ]}
          />
        )}
        {isOpen(MODAL_IDS.ADD) && (
          <DnsSettingModal
            onHide={handleHideModal}
            isShow={isOpen(MODAL_IDS.ADD)}
            isLoading={isLoading}
            data={dnsAdd}
            isAddEmailMode={isAddEmailMode}
            setIsAddEmailMode={setIsAddEmailMode}
            tab={tab}
          />
        )}
        {isOpen(MODAL_IDS.CONFIRM_DELETE) && (
          <DeleteConfirmModal
            isShow
            isLoading={isLoadingDelete}
            title={`${
              tab === NS_SETTING ? 'NS' : 'CNAME'
            }ドメインを削除しますか？`}
            content="下記の設定を使った計測ができなくなります。"
            items={listItemDelete}
            forceText="ドメインを削除しても問題ありません"
            onSubmit={handleSubmitDelete}
            onHide={closeModals}
          />
        )}
        {isOpen(MODAL_IDS.ERROR) && (
          <ConfirmModal
            isShow
            title={error.title}
            content={error.message}
            confirmText="OK"
            cancelBtn={false}
            onSubmit={handleConfirmModal}
          />
        )}
      </DataSyncLoader>
    </div>
  );
}

DnsSettingContainer.propTypes = {
  tab: string.isRequired,
};

export default DnsSettingContainer;
