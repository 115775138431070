import React, { useMemo, useState } from 'react';
import { func, bool, shape, arrayOf, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Button, Form } from 'react-bootstrap';

import {
  APP_HELP_SETUP_CNAME_ABOUT_EMAIL_AUTHENTICATION,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_2,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_3,
  APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4,
  CNAME_SETTING,
} from 'domain/system-setting/consts';

import systemSettingActions from 'store/system-setting/actions';
import TableModal from 'views/organism/TableModal';
import FormInput from 'views/molecules/FormGroup/components/FormInput';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';

import {
  formatDataDnsRequest,
  validateDnsSetting,
  getTitleDnsSetting,
} from 'services/system-setting/systemSettingServices';
import * as messageError from 'services/validations/messageErrorByCode';
import { getErrorMessageByCode } from 'services/utils';
import SettingModal from './Modal';

const COLUMN_DNS_SETTING = [
  { name: 'activation', title: '有効化' },
  {
    name: 'value',
    title: 'ドメイン',
  },
];

const RowItems = (props) => {
  const { rows, onChange } = props;

  const changeSelectBoxValue = (name, subdomain) => () => {
    onChange(name, subdomain);
  };
  return (
    <>
      {rows.map((row, index) => {
        const keyIndex = index;
        return (
          <tr key={keyIndex}>
            {COLUMN_DNS_SETTING.map((column) => {
              if (column.name === 'activation') {
                return (
                  <td>
                    {row?.aspFlag ? (
                      <Form.Check
                        id={`chk-${row.field}`}
                        className="checkbox-item"
                        inline
                        label=""
                        type="checkbox"
                        checked={row.checked}
                        onChange={changeSelectBoxValue(
                          row.field,
                          row.checked ? '' : row.subdomain
                        )}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                );
              }
              return (
                <td>
                  <div className="dns-modal-row">
                    {row?.aspFlag ? (
                      <span>
                        {row.subdomain}.{row.domain}*
                      </span>
                    ) : (
                      <>
                        <FormInput
                          name={row.field}
                          value={row.value}
                          onChange={onChange}
                          style={{ width: 250 }}
                        />
                        <span>.{row.domain}</span>
                      </>
                    )}
                  </div>
                  {row.error && (
                    <div
                      className="dns-modal-row__error"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: row.error }}
                    />
                  )}
                </td>
              );
            })}
          </tr>
        );
      })}
    </>
  );
};

RowItems.propTypes = {
  rows: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
};

function DnsSettingModal(props) {
  const {
    isShow,
    onHide,
    isLoading,
    isAddEmailMode,
    setIsAddEmailMode,
    data,
    tab,
  } = props;
  const dispatch = useDispatch();
  const [valueForm, setValueForm] = useState(
    data.reduce((obj, item) => ({ ...obj, [item.etld1]: '' }), {})
  );
  const [errorForm, setErrorForm] = useState({});
  const [errorMainMessage, setErrorMainMessage] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);

  const handleChangeValue = (name, value) => {
    setValueForm((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
    setErrorForm((prevValue) => ({
      ...prevValue,
      [name]: null,
    }));
  };

  const handleResponse = ({ errors, isError }) => {
    if (!isError) {
      onHide();
      dispatch(systemSettingActions.setStatusScreenComplete(true));
    } else if (isEmpty(errors)) {
      onHide();
    } else if (['MAX_REGISTERED_DOMAINS_CHECK'].includes(errors[0].code)) {
      setIsCreating(false);
      setErrorMainMessage(
        '400件までの計測対象サイトに対してDNS登録が可能です。'
      );
    } else {
      const errorResponse = errors.reduce(
        (acc, error) => ({
          ...acc,
          [error.field]: getErrorMessageByCode(error, messageError),
        }),
        {}
      );
      setIsCreating(false);
      setErrorForm(errorResponse);
    }
  };

  const handleSubmit = () => {
    if (errorMainMessage) setErrorMainMessage('');
    const dataValidate = Object.entries(valueForm).filter(
      ([, value]) => !isEmpty(value.trim())
    );
    const {
      errorMainMessage: errorValidateMainMessage,
      errorForm: errorValidateForm,
    } = validateDnsSetting(dataValidate);
    if (!isEmpty(errorValidateMainMessage)) {
      setErrorMainMessage(errorValidateMainMessage);
    } else if (!isEmpty(errorValidateForm)) {
      setErrorForm(errorValidateForm);
    } else if (isAddEmailMode) {
      setOpenConfirmModal(true);
    } else {
      setIsCreating(true);
      dispatch(
        systemSettingActions.addDnsSetting(
          formatDataDnsRequest(dataValidate, tab, false),
          handleResponse
        )
      );
    }
  };

  const rows = useMemo(() => {
    return data.map((item) => ({
      field: item.etld1,
      aspFlag: item.asp_flag,
      subdomain: item.subdomain,
      checked:
        `${valueForm[item.etld1]}.${item.etld1}` ===
        `${item.subdomain}.${item.etld1}`,
      value: valueForm[item.etld1],
      error: errorForm?.[item.etld1] || '',
      domain: item.etld1,
    }));
  }, [data, valueForm, errorForm]);

  const handleSwitchToAddEmail = () => {
    if (errorMainMessage) {
      setErrorMainMessage('');
    }
    if (!isEmpty(errorForm)) {
      setErrorForm({});
    }
    setIsAddEmailMode(true);
  };

  const handleSubmitConfirmModal = () => {
    const dataRequest = Object.entries(valueForm).filter(
      ([, value]) => !isEmpty(value.trim())
    );
    setOpenConfirmModal(false);
    setIsCreating(true);
    dispatch(
      systemSettingActions.addDnsSetting(
        formatDataDnsRequest(dataRequest, tab, isAddEmailMode),
        handleResponse
      )
    );
  };

  const onHideConfirmModal = (status) => setOpenConfirmModal(status);

  return (
    <SettingModal
      loading={isLoading || isCreating}
      confirmText={isAddEmailMode ? '注意内容は確認済みです' : ''}
      isShowCheckbox={isAddEmailMode}
      isShow={isShow}
      onSubmit={handleSubmit}
      onHide={onHide}
      title={getTitleDnsSetting(tab, isAddEmailMode)}
      dialogClassName="dns-setting-modal"
      elementBelowLoading={
        <>
          証明書審査用レコード
          <br /> 取得中…
        </>
      }
    >
      {!isAddEmailMode && (
        <div className="dns-description">
          <div>
            1ドメインにつき「NS」「CNAME」両方で設定されている場合、「CNAME」の設定は２週間で自動的に削除されます。
          </div>

          <div className="dns-note">
            ※[*]がついているドメインは、サービス提供元でDNS設定済のため、当サービス管理画面の設定のみで完了します。「有効化」のみにチェックしてご登録ください
            <br />
            ※400件までの計測対象サイトに対してDNS設定が可能です。一度に登録ができるのは20件までです
            <br />
            ※ドメインは64文字以内
          </div>
        </div>
      )}
      {tab === CNAME_SETTING && !isAddEmailMode && (
        <div className="dns-cname__note">
          証明書審査用ホスト名の先頭に「_」が登録できない場合は、
          <Button variant="link" size="sm" onClick={handleSwitchToAddEmail}>
            こちら
          </Button>
          からメール審査の手続きを行ってください。
        </div>
      )}
      <div className={isAddEmailMode ? 'dns-cname__contain-table' : ''}>
        {errorMainMessage && (
          <div className="dns-error__main-message">{errorMainMessage}</div>
        )}
        <TableModal
          header={
            <tr>
              {COLUMN_DNS_SETTING.map((column) => (
                <th>{column.title}</th>
              ))}
            </tr>
          }
          body={
            <RowItems
              columns={COLUMN_DNS_SETTING}
              rows={rows}
              onChange={handleChangeValue}
            />
          }
          variant={isAddEmailMode ? 'mt-20' : ''}
          maxContent={258}
          alignScroll={-20}
        />
      </div>
      {isAddEmailMode && (
        <div className="dns-email__note-container">
          <div className="dns-email__note-title">
            メール審査にあたり、下記注意事項を必ずご確認ください。
          </div>
          <div className="dns-email__note-content">
            ・
            <a
              href={APP_HELP_SETUP_CNAME_ABOUT_EMAIL_AUTHENTICATION}
              target="__blank"
            >
              概要
            </a>
            を必ず事前にご確認ください。
            <br />
            ・メール送付先はドメイン管理用メールアドレスとなり任意の変更はできません。「OK」ボタンをクリックするとAWSが審査メールを送信するため、必ず、ドメイン管理者様の許可を得た後にご登録ください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_2}
              target="__blank"
            >
              こちら
            </a>
            <br />
            ・本登録後、メール審査手続きのほか、DNSに計測用CNAMEの登録が必要です。お手続きには72時間の有効期限があるため、登録後はお早めにご対応ください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_3}
              target="__blank"
            >
              こちら
            </a>
            <br />
            ・証明書の有効期限は1年間となり、更新手続きが必要なケースがあります。更新手続きが必要な場合、AWSから更新手続きのメールが送付されますのでお手続きを行ってください。詳しくは
            <a
              href={APP_HELP_SETUP_CNAME_REGISTER_EMAIL_DISCLAIMER_CONTENT_4}
              target="__blank"
            >
              こちら
            </a>
          </div>
        </div>
      )}
      {isOpenConfirmModal && (
        <ConfirmModal
          isShow={isOpenConfirmModal}
          title="入力されたドメイン宛てにメールを送信します"
          content="ドメイン管理者様へのご確認はお済みですか？"
          confirmText="OK"
          cancelText="キャンセル"
          cancelBtn
          onHide={onHideConfirmModal}
          onSubmit={handleSubmitConfirmModal}
        />
      )}
    </SettingModal>
  );
}

DnsSettingModal.propTypes = {
  isShow: bool.isRequired,
  onHide: func.isRequired,
  isLoading: bool.isRequired,
  setIsAddEmailMode: func.isRequired,
  isAddEmailMode: bool.isRequired,
  tab: string.isRequired,
  data: shape({}).isRequired,
};

export default DnsSettingModal;
