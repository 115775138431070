import React from 'react';
import { chain, isEmpty } from 'lodash';

import {
  INFO_FORM_MEASUREMENT_SETTING as MEASUREMENT_LABELS,
  INFO_FORM_BASIC_SETTING,
  INFO_FORM_OTHER_SETTING,
  DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION,
  DNS_MAX_LENGTH,
  DNS_REGEX,
  NS_SETTING,
  CNAME_SETTING,
  HEADER_TABLE_DNS_SETTING,
  HEADER_DNS_COMPLETE_SCREEN,
  HEADER_TABLE_CNAME_SETTING,
  LIMIT_NUMBER_DNS_CALL_API,
  CLICK_MEDIA_SETTING_MEDIA,
} from 'domain/system-setting/consts';
import {
  ROLE_VIEW_AD,
  ROLE_VIEW_ALL,
  ROLE_VIEW_ALL_AD_ONLY,
} from 'domain/permissions/roles';
import {
  basicSettingRules,
  measurementConfigRules,
  measurementLogRules,
  otherSettingRules,
} from 'services/validations/systemSettingRules';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import { IconMultiplePerson } from 'views/atoms/icon/SvgIcon';
import * as messageError from 'services/validations/messageErrorByCode';
import { getErrorMessageByCode } from 'services/utils';
import ErrorMessages from 'services/validations/constants';
import { validUrlByLine } from 'services/validations/commonValidations';

export const renderTextByStatus = (status) => {
  return status ? '有効' : '無効';
};

export const validateDataBasic = (data) => {
  return chain(data)
    .mapValues((value, field) => {
      return {
        label: INFO_FORM_BASIC_SETTING[field].label,
        value: value ?? null,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(basicSettingRules[field])) return [];
      const rulesByField = [...basicSettingRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
};

export const validateBasicSettingForm = (data) => {
  const error = {
    errors: {},
    errorsByLine: [],
    isShowOnTable: false,
  };

  const errorNormalData = validateDataBasic(data);
  if (!isEmpty(errorNormalData)) {
    error.errors = errorNormalData;
  } else {
    const errorByLine = validUrlByLine(
      ErrorMessages.URL_CHECK,
      true
    )({ field: 'host_name', value: data.host_name });
    if (!isEmpty(errorByLine)) {
      error.errorsByLine = errorByLine;
      error.isShowOnTable = true;
    }
  }

  return error;
};

export const formatDisplayEmailInfo = (emailInfo = []) => {
  const NO_EXIST_EMAIL = -1;
  if (emailInfo === NO_EXIST_EMAIL) {
    return '';
  }
  if (emailInfo?.length === 1) {
    return emailInfo[0].email;
  }
  if (emailInfo?.length > 1) {
    const email = emailInfo.reduce(
      (obj, item, index) => {
        const result = obj;
        if (index === 0) {
          result.displayEmail = item.email;
        } else {
          result.tooltipEmail = (
            <>
              {result.tooltipEmail} {isEmpty(result.tooltipEmail) ? '' : <br />}
              {item.email}
            </>
          );
        }
        return result;
      },
      {
        displayEmail: '',
        tooltipEmail: '',
      }
    );
    return (
      <div className="multiple-emails">
        <span>{email.displayEmail}</span>
        <DragonBall
          svg={<IconMultiplePerson />}
          variant="modal-white"
          placement="bottom-end"
        >
          {email.tooltipEmail}
        </DragonBall>
      </div>
    );
  }

  return '';
};

export const formatUsersData = (users) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag, user_roles: userRoles = [] } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      rowId: user.auth_id,
      agent: agentFlag ? icon : null,
      view_ad: userRoles.includes(ROLE_VIEW_AD) ? icon : null,
      view_all:
        userRoles.includes(ROLE_VIEW_ALL) ||
        (userRoles.includes(ROLE_VIEW_ALL_AD_ONLY) && agentFlag)
          ? icon
          : null,
    };
  });
};

export const validateMeasurementConfigSetting = (data) => {
  const resultError = chain(data)
    .mapValues((value, field) => {
      return {
        label: MEASUREMENT_LABELS[field].label,
        value,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(measurementConfigRules[field])) return [];
      const rulesByField = [...measurementConfigRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();
  return resultError;
};

export const validateMeasurementLogSetting = (
  data,
  isAllowedSettingMeasurementOption
) => {
  if (isAllowedSettingMeasurementOption) {
    const resultError = chain(data)
      .mapValues((value, field) => {
        return {
          label: MEASUREMENT_LABELS[field].label,
          value,
        };
      })
      .mapValues((objValue, field) => {
        if (isEmpty(measurementLogRules[field])) return [];
        const keyRulesByField = Object.keys(measurementLogRules[field]);
        const errors = keyRulesByField
          .map((keyRule) => {
            const valueValidate = ['maxLine', 'duplicateValue'].includes(
              keyRule
            )
              ? { ...objValue, value: objValue.value.split(',') }
              : objValue;

            return measurementLogRules[field][keyRule](valueValidate);
          })
          .filter((rule) => !isEmpty(rule));
        return errors;
      })
      .pickBy((errors) => !isEmpty(errors))
      .mapValues((errors) => errors[0])
      .value();
    return resultError;
  }
  return {};
};

export const getErrorMessageResponse = (errors, params = {}) => {
  return errors.reduce((prevError, error) => {
    const { field } = error;
    return {
      ...prevError,
      [field]: getErrorMessageByCode(error, messageError, {
        ...params,
        label: MEASUREMENT_LABELS[field].label,
      }),
    };
  }, {});
};

export const validateOtherSettingForm = (data) => {
  const resultError = chain(data)
    .mapValues((value, field) => {
      return {
        label: INFO_FORM_OTHER_SETTING[field].label,
        value,
      };
    })
    .mapValues((value, field) => {
      if (isEmpty(otherSettingRules[field])) return [];
      const rulesByField = [...otherSettingRules[field]];
      const errors = rulesByField
        .map((rule) => rule(value))
        .filter((rule) => !isEmpty(rule));
      return errors;
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();

  return resultError;
};

export const getErrorResponseOtherSetting = (errors, params = {}) => {
  return errors.reduce((prevError, error) => {
    const { field } = error;
    return {
      ...prevError,
      [field]: getErrorMessageByCode(error, messageError, {
        ...params,
        label: INFO_FORM_OTHER_SETTING[field].label,
      }),
    };
  }, {});
};

export const formatDataDnsRequest = (data = [], tab, isAddEmail) => {
  const listDomain = data.map(([domain, value]) => `${value},${domain}`);
  const listDomainRequest = [];

  while (listDomain.length > 0) {
    const dataRequest = {
      domains: listDomain.splice(0, LIMIT_NUMBER_DNS_CALL_API),
    };
    if (tab === CNAME_SETTING) {
      dataRequest.email_authentication = isAddEmail;
    }
    listDomainRequest.push(dataRequest);
  }
  return { listDomainRequest, isAddEmail };
};

export const validateDnsSetting = (data = []) => {
  const error = {
    errorMainMessage: '',
    errorForm: {},
  };
  if (data.length === 0) {
    error.errorMainMessage = `ドメインは一つ以上入力してください`;
  } else if (data.length > DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION) {
    error.errorMainMessage = `一度に登録ができるドメインの上限は${DNS_SETTINGS_MAX_ENTRIES_PER_REGISTRATION}件までです。`;
  } else {
    data.forEach(([key, value]) => {
      if (value.length > DNS_MAX_LENGTH) {
        error.errorForm = {
          ...error.errorForm,
          [key]: 'ドメインは64字以下で入力してください。',
        };
      } else if (!DNS_REGEX.test(`${value}.${key}`)) {
        error.errorForm = {
          ...error.errorForm,
          [key]: 'ドメインの形式が不正です。',
        };
      }
    });
  }
  return error;
};

export const getTitleDnsSetting = (tab, isAddEmailMode) => {
  if (isAddEmailMode) {
    return (
      <div>
        <div className="modal-title h4">CNAMEドメインを追加（メール審査）</div>
        <div className="dns-note">
          ※[*]がついているドメインは、サービス提供元でDNS設定済のため、当サービス管理画面の設定のみで完了します。「有効化」のみにチェックしてご登録ください
        </div>
      </div>
    );
  }
  return `計測用DNS（${
    tab === NS_SETTING ? 'NSドメイン' : 'CNAMEドメイン'
  }）を追加`;
};

export const getHeaderDnsTable = (isScreenComplete, tab) => {
  if (isScreenComplete) return HEADER_DNS_COMPLETE_SCREEN;
  if (tab === CNAME_SETTING) return HEADER_TABLE_CNAME_SETTING;
  return HEADER_TABLE_DNS_SETTING;
};

export const getClickIdSettingMediaByKey = (listKey) => {
  const listKeyClickId = Object.keys(CLICK_MEDIA_SETTING_MEDIA)
    .filter((key) => listKey.includes(key))
    .map((key) => CLICK_MEDIA_SETTING_MEDIA[key].name);
  return listKeyClickId.join('、');
};
