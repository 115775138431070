export const SYSTEM_RESIST_BLACKLIST = [
  'コンバージョン時間',
  'ユーザー名',
  '売上金額',
  '潜伏期間',
  '接触回数',
  '個別追跡',
  '直接効果(広告ID)',
  '直接効果(広告グループ1)',
  '直接効果(広告グループ2)',
  '直接効果(媒体種別/検索エンジン)',
  '直接効果(広告名/検索ワード)',
  '間接効果(広告ID)',
  '間接効果(広告グループ1)',
  '間接効果(広告グループ2)',
  '間接効果(媒体種別/検索エンジン)',
  '間接効果(広告名/検索ワード)',
  '初回接触(広告ID)',
  '初回接触(クリック時間/流入時間)',
  '初回接触(広告グループ1)',
  '初回接触(広告グループ2)',
  '初回接触(媒体種別/検索エンジン)',
  '初回接触(広告名/検索ワード)',
  'デバイス',
  'ユーザーID',
  '直接効果(検索エンジン)',
  '直接効果(検索ワード)',
  '間接効果(検索エンジン)',
  '間接効果(検索ワード)',
  '初回接触(流入時間)',
  '初回接触(検索エンジン)',
  '初回接触(検索ワード)',
  'クリック数',
  '総PV',
  'CV',
  '広告コスト',
  'CPC',
  'CPA',
  '売上総額',
  'ROAS',
  '広告ID',
  '媒体種別',
  '広告名',
  '出稿期間',
  '流入回数',
  '平均PV',
  '直帰数',
  '直帰率',
  '滞在時間',
  '平均滞在時間',
  'CVR',
  '表示割合',
  '広告接触回数',
  '直接効果',
  '直接効果(媒体種別)',
  '直接効果(広告名)',
  '間接効果',
  '間接効果(ad_group2)',
  '間接効果(媒体種別)',
  '間接効果(広告名)',
  '初回接触(クリック時間)',
  '初回接触',
  '初回接触(媒体種別)',
  '初回接触(広告名)',
  '表示回数',
  'CTR',
  '総滞在時間',
  '間接効果(合計以外)',
  '間接効果(合計)',
  '直間比率',
  '初回広告',
  '総潜伏期間',
  '平均潜伏期間',
  'コンバージョン名',
  'ランディングページ/地域',
  'クリック時間',
  'LPO種別',
  '期間',
  '全体CV',
  '全体CVR',
  '都道府県',
  '出稿開始日',
  '出稿終了日',
  '以前',
  '合計',
  '直接',
  '間接',
  'ランディングページ',
  '流入を評価する',
  'PV数',
  '地域',
  '直接効果(クリック時間)',
  '直接効果(ランディングページ/地域)',
  '間接効果 合計以外',
  '間接効果(クリック時間)',
  '間接効果 合計',
  '直接効果(クリック時間/流入時間)',
  '直接効果(流入時間)',
  '間接効果(クリック時間/流入時間)',
  '間接効果(流入時間)',
  'CVUU',
  'CV数',
  '再配分CV',
  'ID',
  'UU',
  'TCPA',
  'TROAS',
  'カテゴリ',
  'クリック/流入回数',
  'セッション数',
  'チャネル種別',
  'ランディングページURL',
  'ランディングページドメイン',
  '再配分売上総額',
  '名称',
  '平均売上金額',
  '平均接触回数',
  '平均潜伏期間(秒)',
  '潜伏期間(秒)',
  '発生日時',
  'クリック単価',
  'タイトル',
  'テキスト原稿',
  'リンク先URL',
  '入稿用URL',
  '最終更新日時',
  '登録日時',
  '親広告ID',
  'コンバージョン単価',
  '入稿用URL(Google 広告/YSS用)',
  '計測方式',
];

// TODO remove if add new const
export default SYSTEM_RESIST_BLACKLIST;
