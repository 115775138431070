import { SettingsApi as Api } from 'services/api/Api';
import { SYSTEM_SETTING } from 'services/routes/constants';
import pages from 'services/routes/pages';
import { MEASUREMENT_SETTING_ENDPOINTS } from 'domain/system-setting/consts';

const SystemSettingApiFactory = () => {
  const url = pages[SYSTEM_SETTING].endpoint;
  return {
    getBasicSetting: async () => {
      return Api.get(`${url}/basic`);
    },
    updateBasicSetting: async (data) => {
      return Api.put(`${url}/basic`, data);
    },
    getCrossDomainList: async (data) => {
      return Api.post(`${url}/tld`, data);
    },
    getMeasurementSetting: async () => {
      return Api.get(`${url}/measure`);
    },
    updateMeasurementSetting: async (endpoint, data) => {
      return Api.put(
        `${url}/measure/${MEASUREMENT_SETTING_ENDPOINTS[endpoint]}`,
        data
      );
    },
    getOtherSetting: async () => {
      return Api.get(`${url}/other`);
    },
    updateOtherSetting: async (data) => {
      return Api.put(`${url}/other`, data);
    },
    getDnsSetting: async (tab, setupType = 1) => {
      return Api.get(`${url}/dns/${tab}?setup=${setupType}`);
    },
    addDnsSetting: async (tab, certType, data) => {
      return Api.post(`${url}/dns/${tab}?cert=${certType}`, data);
    },
    deleteDnsSetting: async (tab, data) => {
      return Api.delete(`${url}/dns/${tab}`, { data: { etld1: data } });
    },
  };
};

const SystemSettingApi = SystemSettingApiFactory();
export default SystemSettingApi;
