import { SYSTEM_SETTING_TAB } from 'domain/system-setting/consts';
import { communicationStatus } from 'services/utils';
import types from 'store/system-setting/types';
import helper from 'store/helper';

const { IDLE, LOADING, SUCCEEDED } = communicationStatus;
const initialState = {
  data: {
    basic: {
      data: {},
      errors: [],
      crossDomainList: '',
    },
    measurement: {
      data: {},
      errors: [],
    },
    other: {
      data: {},
      errors: [],
    },
    cookieProgram: {
      setCookieSettings: [],
      measurementFqdn: [],
      settingsCheckStatus: {},
      errors: [],
    },
    dns: {
      data: [],
      dnsAdd: [],
      isEmptyHostName: false,
      isDisabledRegister: false,
      isScreenComplete: false,
      errors: [],
    },
  },
  settings: {
    tab: SYSTEM_SETTING_TAB.BASIC,
  },
  status: {
    basic: IDLE,
    cookieProgram: LOADING,
    cookieProgramCheck: IDLE,
    measurement: IDLE,
    other: IDLE,
    dns: IDLE,
  },
};

const setCookieProgramSettings = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      cookieProgram: {
        ...state.data.cookieProgram,
        setCookieSettings: data.setcookie_settings,
        measurementFqdn: data.measurement_fqdns,
        errors: [],
      },
    },
  };
};

const setCookieProgramSettingsCheckStatus = (state, action) => {
  const { data } = action.payload;
  return {
    ...state,
    data: {
      ...state.data,
      cookieProgram: {
        ...state.data.cookieProgram,
        settingsCheckStatus: {
          ...state.data.cookieProgram.settingsCheckStatus,
          [data.measurement_fqdn]: {
            status: data.status,
            cookieValue: data.cookieValue,
          },
        },
      },
    },
  };
};

const setCheckingCookieProgramUrl = (state, action) => {
  const { measurementFqdns } = action.payload;
  let updatedState = state;
  measurementFqdns.forEach((measurementFqdn) => {
    updatedState = {
      ...updatedState,
      data: {
        ...updatedState.data,
        cookieProgram: {
          ...updatedState.data.cookieProgram,
          settingsCheckStatus: {
            ...updatedState.data.cookieProgram.settingsCheckStatus,
            [measurementFqdn]: { status: null, cookieValue: null },
          },
        },
      },
    };
  });
  return updatedState;
};

const setStatusCookieProgramSettings = (state, action) => {
  const { status } = action.payload;
  return {
    ...state,
    status: {
      ...state.status,
      cookieProgram: status,
    },
  };
};

const setStatusCookieProgramChecking = (state, action) => {
  const { status } = action.payload;
  return {
    ...state,
    status: {
      ...state.status,
      cookieProgramCheck: status,
    },
  };
};

const setDataBasicSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    basic: {
      ...state.data.basic,
      data: action.payload.data,
      crossDomainList: action.payload.data.cross_domain_list,
      errors: [],
    },
  },
  status: {
    ...state.status,
    basic: SUCCEEDED,
  },
});

const setDataMeasurementSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    measurement: {
      ...state.data.measurement,
      data: action.payload.data,
      errors: [],
    },
  },
  status: {
    ...state.status,
    measurement: SUCCEEDED,
  },
});

const setCrossDomainList = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    basic: {
      ...state.data.basic,
      crossDomainList: action.payload.data.cross_domain_list,
      errors: [],
    },
  },
  status: {
    ...state.status,
    basic: SUCCEEDED,
  },
});

const setStatusMeasurementSetting = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    measurement: action.payload.status,
  },
});

const setStatuBasicSetting = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    basic: action.payload.status,
  },
});

const setStatusOtherSetting = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    other: action.payload.status,
  },
});

const setDataOtherSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    other: {
      ...state.data.other,
      data: action.payload.data,
      errors: [],
    },
  },
  status: {
    ...state.status,
    other: SUCCEEDED,
  },
});

const changeTab = (state, action) => ({
  ...state,
  settings: {
    ...state.settings,
    tab: action.payload.tab,
  },
});

const cleanStates = (state, action) => ({
  ...initialState,
  settings: {
    ...initialState.settings,
    tab: action.payload.tab,
  },
});

const setDataDnsSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    dns: {
      ...state.data.dns,
      data: action.payload.data.list,
      isEmptyHostName: action.payload.data.isEmptyHostName,
      isDisabledRegister: action.payload.data.isDisabledRegister,
      isScreenComplete: action.payload.data.isScreenComplete,
      errors: [],
    },
  },
  status: {
    ...state.status,
    dns: SUCCEEDED,
  },
});

const setDataDnsAddSetting = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    dns: {
      ...state.data.dns,
      dnsAdd: action.payload.dnsAdd,
    },
  },
});

const setStatusDnsSetting = (state, action) => ({
  ...state,
  status: {
    ...state.status,
    dns: action.payload.status,
  },
});

const setStatusScreenComplete = (state, action) => ({
  ...state,
  data: {
    ...state.data,
    dns: {
      ...state.data.dns,
      isScreenComplete: action.payload.status,
    },
  },
});

const SystemSettingReducer = helper.createReducer(initialState, {
  [types.CHANGE_TAB]: changeTab,
  [types.CLEAN_STATES]: cleanStates,
  [types.SET_COOKIE_PROGRAM_SETTINGS]: setCookieProgramSettings,
  [types.SET_COOKIE_PROGRAM_SETTINGS_CHECK_STATUS]: setCookieProgramSettingsCheckStatus,
  [types.SET_CHECKING_COOKIE_PROGRAM_SETTINGS]: setCheckingCookieProgramUrl,
  [types.SET_STATUS_COOKIE_PROGRAM_SETTINGS]: setStatusCookieProgramSettings,
  [types.SET_STATUS_COOKIE_PROGRAM_CHECKING]: setStatusCookieProgramChecking,
  [types.SET_STATUS_SETTING_BASIC]: setStatuBasicSetting,
  [types.SET_DATA_SETTING_BASIC]: setDataBasicSetting,
  [types.SET_DATA_MEASUREMENT_SETTING]: setDataMeasurementSetting,
  [types.SET_STATUS_MEASUREMENT_SETTING]: setStatusMeasurementSetting,
  [types.SET_CROSS_DOMAIN_LIST]: setCrossDomainList,
  [types.SET_STATUS_OTHER_SETTING]: setStatusOtherSetting,
  [types.SET_DATA_OTHER_SETTING]: setDataOtherSetting,
  [types.SET_STATUS_DNS_SETTING]: setStatusDnsSetting,
  [types.SET_DATA_DNS_SETTING]: setDataDnsSetting,
  [types.SET_DATA_DNS_ADD_SETTING]: setDataDnsAddSetting,
  [types.SET_STATUS_SCREEN_COMPLETE]: setStatusScreenComplete,
});

export default SystemSettingReducer;
