import { createSelector } from 'reselect';
import { communicationStatus, getErrorMessageByCode } from 'services/utils';
import { masterDataRecordsToSelectItems } from 'domain/utils';
import {
  AD_MANAGEMENT_MODE,
  AD_GROUP_MAX_VALUE,
  MEDIA_MAX_VALUE,
  RANK_MAX,
} from 'domain/ad-management/consts';
import { UNREGISTERED_ID_FILTER_KEY } from 'domain/consts';
import { formatMasterdataForSettings } from 'services/ad-management/adManagementService';
import masterDataSelectors from 'store/master-data/selectors';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import adManagementMediaSelectors from 'store/ad-management-media/selectors';
import adManagementAdGroup1Selectors from 'store/ad-management-adgroup1/selectors';
import adManagementAdGroup2Selectors from 'store/ad-management-adgroup2/selectors';
import { SORT_DIRECTION_NONE } from 'domain/settings/display-items';
import {
  AD_ID,
  PASTE_URL,
  LINK_URL,
  TEXT_DATA,
  TITLE,
  TRANSITION_RATE,
} from 'domain/settings/display-items-ad-management';
import {
  MEDIA_ID,
  AD_GROUP1,
  AD_GROUP1_ID,
  AD_GROUP2,
  AD_GROUP2_ID,
  MEASUREMENT_TYPE,
  AGENCY_ID,
  MEDIA_ACCOUNT_ID,
  MEDIA_SIDE_CAMPAIGN_ID,
  MEDIA_SIDE_GROUP_ID,
  MEDIA_SIDE_FINAL_URL,
} from 'domain/fields';

const getList = (state) => state.cache.adManagement.data.table.list;
const getListError = (state) => state.cache.adManagement.data.table.errors;
const getDetailData = (state) => state.cache.adManagement.data.detail.item;
const getDetailError = (state) => state.cache.adManagement.data.detail.errors;

const getUploadData = (state) => state.cache.adManagement.data.upload.data;
const getUploadError = (state) => state.cache.adManagement.data.upload.errors;

const getTab = (state) => state.cache.adManagement.settings.tab;
const getSort = (state) => state.cache.adManagement.settings.table.sort;
const getRemain = (state) => state.cache.adManagement.settings.table.remain;
const getPagination = (state) =>
  state.cache.adManagement.settings.table.pagination;

const getStatusTable = (state) =>
  state.cache.adManagement.apiStatus.table.status;
const getStatusDetail = (state) =>
  state.cache.adManagement.apiStatus.detail.status;
const getStatusUpload = (state) =>
  state.cache.adManagement.apiStatus.upload.status;

const getUploadFileName = (state) =>
  state.cache.adManagement.data.upload.filename;

function createHeader(name, displayItem, sort) {
  const sortValue =
    sort.field === name ? sort.direction : displayItem.sortDefault;

  const cellDisplay = {
    [AD_ID]: 'viewDetail',
    [PASTE_URL]: 'linkExternal',
    [LINK_URL]: 'linkExternal',
    [TEXT_DATA]: 'collapse',
    [MEDIA_SIDE_FINAL_URL]: 'linkExternal',
  };
  const expandCellTable = [
    TEXT_DATA,
    TITLE,
    TRANSITION_RATE,
    LINK_URL,
  ].includes(name)
    ? { isExpandCellTable: true }
    : {};

  return {
    name,
    text: displayItem.title,
    isDimension: displayItem.displayFreeze,
    sort: !displayItem.sortDisabled ? sortValue : false,
    cellDisplay: cellDisplay[name] || '',
    ...expandCellTable,
  };
}

const isTableUpdating = (state) =>
  [communicationStatus.UPDATING].includes(
    state.cache.adManagement.apiStatus.table.status
  );

const isDetailLoading = (state) =>
  [communicationStatus.LOADING].includes(
    state.cache.adManagement.apiStatus.detail.status
  );

const isLoadingPage = createSelector(
  [adManagementSettingsSelectors.getDisplayItemsStatus, getStatusTable],
  (statusDisplayItems, statusTable) => {
    return [statusDisplayItems, statusTable].some((status) =>
      [communicationStatus.IDLE, communicationStatus.LOADING].includes(status)
    );
  }
);

const getHeaders = createSelector(
  [adManagementSettingsSelectors.getDisplayItemsForTable, getSort, getTab],
  (displayItems, sort, tab) => {
    const headers = Object.entries(displayItems)
      .sort((a, b) => a[1].order - b[1].order)
      .map(([name, displayItem]) => {
        return createHeader(name, displayItem, sort);
      });
    const checkAllHeader = {
      text: '',
      name: 'check_all',
      isDimension: true,
      isEdit: tab !== AD_MANAGEMENT_MODE.DELETED,
      sort: SORT_DIRECTION_NONE,
      checked: false,
      variant: '',
    };

    return [checkAllHeader].concat(headers);
  }
);

const getItemsDisplay = createSelector(
  [adManagementSettingsSelectors.getDisplayItemsSettingsOrDefault],
  (displayItems) => Object.keys(displayItems)
);

const getSortRequest = createSelector(
  [getItemsDisplay, getSort],
  (itemsDisplay, sort) => {
    const mappingField = {
      [AD_GROUP1]: AD_GROUP1_ID,
      [AD_GROUP2]: AD_GROUP2_ID,
    };
    const { field } = sort;
    let { direction } = sort;
    if (itemsDisplay.includes(field)) {
      if (field === MEASUREMENT_TYPE) {
        // Reverse sort value for correct display with data text
        direction = direction === 'asc' ? 'desc' : 'asc';
      }
      return (direction === 'asc' ? '' : '-') + (mappingField[field] || field);
    }

    return undefined;
  }
);

const getMedia = createSelector(
  [
    masterDataSelectors.getMedia,
    adManagementMediaSelectors.getDataDetail,
    adManagementMediaSelectors.getStatusDetail,
    adManagementMediaSelectors.getErrorDetail,
  ],
  (list, item, status, errors) => ({
    list: formatMasterdataForSettings(list),
    item,
    status,
    error: errors[0] || {},
  })
);

const getAdGroup1 = createSelector(
  [
    masterDataSelectors.getAdGroup1,
    adManagementAdGroup1Selectors.getDataDetail,
    adManagementAdGroup1Selectors.getStatusDetail,
    adManagementAdGroup1Selectors.getErrorDetail,
  ],
  (list, item, status, errors) => ({
    list: formatMasterdataForSettings(list),
    item,
    status,
    error: errors[0] || {},
  })
);

const getAdGroup2 = createSelector(
  [
    masterDataSelectors.getAdGroup2,
    adManagementAdGroup2Selectors.getDataDetail,
    adManagementAdGroup2Selectors.getStatusDetail,
    adManagementAdGroup2Selectors.getErrorDetail,
  ],
  (list, item, status, errors) => ({
    list: formatMasterdataForSettings(list),
    item,
    status,
    error: errors[0] || {},
  })
);

const masterData = createSelector(
  [getMedia, getAdGroup1, getAdGroup2, masterDataSelectors.getForAdManagement],
  (media, adGroup1, adGroup2, master) => {
    const {
      status,
      search,
      display,
      agency,
      mediaAccount,
      mediaSideCampaign,
      mediaSideGroup,
    } = master;
    const isLoading = status === 'loading';
    const searchResult = masterDataRecordsToSelectItems(search.items);
    const agencySetting = masterDataRecordsToSelectItems(agency, [
      UNREGISTERED_ID_FILTER_KEY,
    ]);
    const errorMessage = {
      AD_GROUP1_NAME_EXIST_CHECK:
        '利用されている{label}です。別の{label}を入力してください。',
      AD_GROUP2_NAME_EXIST_CHECK:
        '利用されている{label}です。別の{label}を入力してください。',
      MEDIA_NAME_EXIST_CHECK:
        '利用されている{label}です。別の{label}を入力してください。',
      MAX_RANK_CHECK: '並び順は{rank}字以下で入力してください。',
      LIMIT_CHECK:
        '{label}に登録上限数{max}件を超えてしまうため、登録できません。',
      LINE_VALUE_IS_EXISTS:
        '利用されている{label}です。別の{label}を入力してください。',
      ELEMENT_ENCODING_CHECK:
        '使用できない文字（特殊な記号や常用外漢字など）が含まれています',
      OVER_MAX_RECORD:
        '{label}に登録上限数{max}件を超えてしまうため、登録できません。',
    };

    return {
      display,
      agency: {
        isLoading,
        list: search.type === AGENCY_ID ? searchResult : agencySetting,
      },
      media: {
        isLoading,
        status: media.status,
        item: media.item,
        list: search.type === MEDIA_ID ? searchResult : media.list,
        error: getErrorMessageByCode(media.error, errorMessage, {
          label: '媒体種別',
          rank: RANK_MAX,
          max: MEDIA_MAX_VALUE,
        }),
      },
      adGroup1: {
        isLoading,
        status: adGroup1.status,
        item: adGroup1.item,
        list: search.type === AD_GROUP1_ID ? searchResult : adGroup1.list,
        error: getErrorMessageByCode(adGroup1.error, errorMessage, {
          label: display[AD_GROUP1_ID],
          rank: RANK_MAX,
          max: AD_GROUP_MAX_VALUE,
        }),
      },
      adGroup2: {
        isLoading,
        status: adGroup2.status,
        item: adGroup2.item,
        list: search.type === AD_GROUP2_ID ? searchResult : adGroup2.list,
        error: getErrorMessageByCode(adGroup2.error, errorMessage, {
          label: display[AD_GROUP2_ID],
          rank: RANK_MAX,
          max: AD_GROUP_MAX_VALUE,
        }),
      },
      mediaAccount: {
        isLoading,
        list: search.type === MEDIA_ACCOUNT_ID ? searchResult : mediaAccount,
      },
      mediaSideCampaign: {
        isLoading,
        list:
          search.type === MEDIA_SIDE_CAMPAIGN_ID
            ? searchResult
            : mediaSideCampaign,
      },
      mediaSideGroup: {
        isLoading,
        list:
          search.type === MEDIA_SIDE_GROUP_ID ? searchResult : mediaSideGroup,
      },
    };
  }
);

const isContractedLpoBefore = (state) =>
  state.cache.adManagement.lpoRenegotiation;

const getLpoRegistPageMax = (state) =>
  state.cache.adManagement.lpoRegistPageMax;

const adManagementSelectors = {
  getTab,
  getList,
  getListError,
  getDetailData,
  getDetailError,
  getUploadData,
  getUploadError,
  getPagination,
  getStatusTable,
  getUploadFileName,
  getStatusDetail,
  getStatusUpload,
  getHeaders,

  isLoadingPage,
  isTableUpdating,
  isDetailLoading,
  getSort,
  getSortRequest,
  getRemain,

  masterData,
  isContractedLpoBefore,
  getLpoRegistPageMax,
};

export default adManagementSelectors;
