import settingsSelectors from 'store/settings/selectors';
import { createSelector } from 'reselect';

const getTab = (state) => state.cache.systemSetting.settings.tab;

const getCookieProgramSettings = (state) =>
  state.cache.systemSetting.data.cookieProgram.setCookieSettings;

const getMeasurementFqdn = (state) =>
  state.cache.systemSetting.data.cookieProgram.measurementFqdn;

const getCookieProgramSettingsCheckStatus = (state) =>
  state.cache.systemSetting.data.cookieProgram.settingsCheckStatus;

const getCookiePersisntenceStatus = (state) =>
  state.cache.systemSetting.status.cookieProgram;
const getCookiePersisntenceCheckingStatus = (state) =>
  state.cache.systemSetting.status.cookieProgramCheck;

const getRemainCookieProgramSetting = (state) =>
  state.cache.systemSetting.data.cookieProgram.measurementFqdn.length -
  state.cache.systemSetting.data.cookieProgram.setCookieSettings.length;

// basic setting
const getDataBasicSetting = (state) =>
  state.cache.systemSetting.data.basic.data;
const getStatusBasicSetting = (state) => state.cache.systemSetting.status.basic;
const getCrossDomainList = (state) =>
  state.cache.systemSetting.data.basic.crossDomainList;

const getDataMeasurementSetting = (state) =>
  state.cache.systemSetting.data.measurement.data;
const getStatusMeasurementSetting = (state) =>
  state.cache.systemSetting.status.measurement;

const getDataOtherSetting = (state) =>
  state.cache.systemSetting.data.other.data;
const getStatusOtherSetting = (state) => state.cache.systemSetting.status.other;

const getDataDnsSetting = (state) => state.cache.systemSetting.data.dns.data;
const getStatusListHostName = (state) =>
  state.cache.systemSetting.data.dns.isEmptyHostName;
const getIsDisableRegister = (state) =>
  state.cache.systemSetting.data.dns.isDisabledRegister;
const getStatusDnsSetting = (state) => state.cache.systemSetting.status.dns;
const getDnsAddSetting = (state) => state.cache.systemSetting.data.dns.dnsAdd;
const getStatusScreenComplete = (state) =>
  state.cache.systemSetting.data.dns.isScreenComplete;

const getStatesBasicSetting = createSelector(
  [
    getDataBasicSetting,
    getCrossDomainList,
    settingsSelectors.getLoadingByStatus([getStatusBasicSetting]),
  ],
  (data, crossDomainList, statusBasicSetting) => {
    return {
      data,
      crossDomainList,
      isLoading: statusBasicSetting,
    };
  }
);

const getStatesMeasurementSetting = createSelector(
  [
    getDataMeasurementSetting,
    settingsSelectors.getLoadingByStatus([getStatusMeasurementSetting]),
  ],
  (data, statusBasicSetting) => {
    return {
      data,
      isLoading: statusBasicSetting,
    };
  }
);

const getStatesOtherSetting = createSelector(
  [
    getDataOtherSetting,
    settingsSelectors.getLoadingByStatus([getStatusOtherSetting]),
  ],
  (data, statusOtherSetting) => {
    return {
      data,
      isLoading: statusOtherSetting,
    };
  }
);

const getStatesDnsSetting = createSelector(
  [
    getDataDnsSetting,
    getStatusListHostName,
    getIsDisableRegister,
    getDnsAddSetting,
    getStatusScreenComplete,
    settingsSelectors.getLoadingByStatus([getStatusDnsSetting]),
  ],
  (
    data,
    isEmptyHostName,
    isDisabledRegister,
    dnsAdd,
    isScreenComplete,
    statusDnsSetting
  ) => {
    return {
      data,
      isEmptyHostName,
      isDisabledRegister,
      dnsAdd,
      isScreenComplete,
      isLoading: statusDnsSetting,
    };
  }
);

export default {
  getDataMeasurementSetting,
  getTab,
  getCookieProgramSettings,
  getMeasurementFqdn,
  getCookieProgramSettingsCheckStatus,
  getCookiePersisntenceStatus,
  getCookiePersisntenceCheckingStatus,
  getRemainCookieProgramSetting,
  getStatesBasicSetting,
  getStatesMeasurementSetting,
  getStatesOtherSetting,
  getStatesDnsSetting,
  getStatusScreenComplete,
};
