import axios from 'axios';
import { SettingsApi as Api } from 'services/api/Api';
import DisplayItemsApi from 'services/api/DisplayItemsApi';
import isNumber from 'lodash/isNumber';
import FilterService from 'domain/FilterService';
import pages from 'services/routes/pages';
import apiUtils from 'services/apiUtils';
import { saveAs } from 'services/utils';
import { AD_MANAGEMENT } from 'services/routes/constants';
import {
  AD_MANAGEMENT_MODE,
  REMAIN_ITEMS_REGISTER,
} from 'domain/ad-management/consts';
import { FUNC_CODE_AD_MANAGEMENT } from 'domain/settings/display-items';
import {
  AD_MANAGEMENT_STATUS_ACTION,
  PRIORITY_AXIS_BOTH,
  TABLE_ROWS_PER_PAGE,
} from 'domain/consts';
import {
  DEVICE,
  LINK_URL,
  TITLE,
  PASTE_URL,
  PASTE_URL_PARALLEL,
  TRANSITION_RATE,
} from 'domain/settings/display-items-ad-management';

const PATH_BY_MODE = {
  [AD_MANAGEMENT_MODE.AD]: 'ebis',
  [AD_MANAGEMENT_MODE.LPO]: 'lpo',
  [AD_MANAGEMENT_MODE.LISTING]: 'listing',
  [AD_MANAGEMENT_MODE.ALL]: '',
  [AD_MANAGEMENT_MODE.DELETED]: 'recycle-bin',
};

export const AD_MANAGEMENT_API_ENDPOINT = pages[AD_MANAGEMENT].endpoint;

const AdManagementApiFactory = () => {
  const buildCsvValidationRequest = (filename) => ({
    file_name: filename,
  });

  const buildSubmitCsvRequest = (filename) => ({
    file_name: filename,
  });

  const buildUpdateUrl = (type, id) => {
    // Use encodeURI to encoding of the character [] of id adPlan
    const parts = [AD_MANAGEMENT_API_ENDPOINT, 'ad', type, encodeURI(id)];

    return parts.filter((part) => !!part).join('/');
  };

  const buildChangeStatusUrl = (type) => {
    const parts = [
      AD_MANAGEMENT_API_ENDPOINT,
      PATH_BY_MODE[AD_MANAGEMENT_MODE.DELETED],
      type,
    ];
    return parts.filter((part) => !!part).join('/');
  };

  const buildChangeStatusRequest = (ids) => {
    return {
      ad_ids: ids,
    };
  };

  const buildSearchRequest = (options = {}) => {
    const { limit, sort } = options;
    const filters = FilterService.prepareForApi(
      options.filters,
      PRIORITY_AXIS_BOTH
    );
    const offset = (options.page - 1) * limit;
    return {
      filters,
      sort,
      limit,
      offset,
      lpoRenegotiation: options.lpoRenegotiation,
    };
  };

  const buildDownloadCsvRequest = (options = {}) => {
    const searchRequest = buildSearchRequest(options);
    return {
      filters: searchRequest.filters,
      sort: searchRequest.sort,
    };
  };

  const buildSearchUrl = (mode, endpoint = 'list') => {
    const parts = [
      AD_MANAGEMENT_API_ENDPOINT,
      'ad',
      PATH_BY_MODE[mode],
      endpoint,
    ];
    if (mode === AD_MANAGEMENT_MODE.DELETED) {
      parts.splice(1, 1);
    }

    return parts.filter((part) => !!part).join('/');
  };

  const buildChangeStatusByFiltersRequest = (filters, excludedIds, mode) => {
    return {
      ad_mode: mode,
      filters: [
        ...FilterService.prepareForApi(filters, PRIORITY_AXIS_BOTH),
        { field: 'excluded_ad_id', value: excludedIds },
      ],
    };
  };

  const getUploadUrl = async (contentType) => {
    const query = { 'content-type': contentType };
    const url = apiUtils.buildUrlWithQueryStrings(
      `${AD_MANAGEMENT_API_ENDPOINT}/upload-url`,
      query
    );
    const response = await Api.get(url);
    const { upload_url: uploadUrl, file_name: filename } = response.data.data;
    return {
      uploadUrl,
      filename,
    };
  };

  const uploadFile = async (file, url, contentType) => {
    return axios.put(url, file, {
      headers: {
        'Content-Type': contentType,
        'X-Amz-Acl': 'bucket-owner-full-control',
      },
    });
  };

  const validateCsv = async (filename, mode) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/ad/${PATH_BY_MODE[mode]}/csv-validate`;
    const request = buildCsvValidationRequest(filename);

    // API response is json file
    let response = await Api.requestAsync(url, request, 'POST', {
      pollUrlFormat: `${url}/{task}`,
      pollTokenKey: 'task',
      retryIntervalLow: process.env.REACT_APP_ASYNC_INTERVAL_LOW || 1000,
      retryIntervalLowMax: process.env.REACT_APP_ASYNC_INTERVAL_LOW_MAX || 10,
    });
    // API response is data json
    response = await Api.get(response.data.data.location);

    return response;
  };

  const submitCsv = async (filename, mode) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/ad/${PATH_BY_MODE[mode]}/csv-confirm`;
    const request = buildSubmitCsvRequest(filename);

    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
      retryIntervalLow: process.env.REACT_APP_ASYNC_INTERVAL_LOW || 1000,
      retryIntervalLowMax: process.env.REACT_APP_ASYNC_INTERVAL_LOW_MAX || 10,
    });

    return response;
  };

  const validateCsvDelete = async (filename, mode) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/ad/${PATH_BY_MODE[mode]}/recycle-bin/confirm`;
    const request = buildCsvValidationRequest(filename);

    // API response is json file
    let response = await Api.requestAsync(url, request, 'POST', {
      pollUrlFormat: `${url}/{task}`,
      pollTokenKey: 'task',
    });
    // API response is data json
    response = await Api.get(response.data.data.location);

    return response;
  };

  const submitCsvDelete = async (filename, mode) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/ad/${PATH_BY_MODE[mode]}/recycle-bin/complete`;
    const request = buildSubmitCsvRequest(filename);

    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
    });

    return response;
  };

  const createDisplayItems = async (requestData) => {
    return DisplayItemsApi.create(FUNC_CODE_AD_MANAGEMENT, requestData);
  };

  const fetchDisplayItems = async () => {
    return DisplayItemsApi.fetch(FUNC_CODE_AD_MANAGEMENT);
  };

  const createAd = async (data, mode) => {
    if (!data || !mode) {
      throw new Error('data and mode should not be empty');
    }

    const response = await Api.post(
      `${AD_MANAGEMENT_API_ENDPOINT}/ad/${PATH_BY_MODE[mode]}`,
      data
    );
    const { data: createdData } = response.data;
    return createdData;
  };

  const updateAd = async (id, data) => {
    const { type } = data;
    if (!id || !type) {
      throw new Error('Invalid id or type');
    }
    const url = buildUpdateUrl(type, id);
    const { ad_id: adId, type: adType, ...dataPost } = data;
    const response = await Api.put(url, dataPost);
    const { data: updatedData } = response.data;
    return updatedData;
  };

  /**
   * Temporary delete. Can be restored later
   * @param ids
   */
  const deleteAds = async (ids) => {
    const url = buildChangeStatusUrl(AD_MANAGEMENT_STATUS_ACTION.DELETE);
    const request = buildChangeStatusRequest(ids);

    return Api.post(url, request);
  };

  /**
   * Permanently delete
   * @param ids
   */
  const dropAds = async (ids) => {
    const url = buildChangeStatusUrl(AD_MANAGEMENT_STATUS_ACTION.DROP);
    const request = buildChangeStatusRequest(ids);

    return Api.post(url, request);
  };

  const restoreAds = async (ids) => {
    const url = buildChangeStatusUrl(AD_MANAGEMENT_STATUS_ACTION.RESTORE);
    const request = buildChangeStatusRequest(ids);

    return Api.put(url, request);
  };

  const getDetail = async (id) => {
    if (!id) {
      return null;
    }

    // Use encodeURI to encoding of the character [] of id adPlan
    const response = await Api.get(
      `${AD_MANAGEMENT_API_ENDPOINT}/${encodeURI(id)}`
    );

    const { data } = response?.data;
    return data;
  };

  const search = async (options = {}, mode) => {
    const initOptions = {
      filters: {},
      sort: null,
      page: 1,
      limit: TABLE_ROWS_PER_PAGE,
    };

    const requestOptions = {
      ...initOptions,
      ...options,
    };

    const request = buildSearchRequest(requestOptions);

    // if sync timeout (29s) => will be call api async to get list
    const syncUrl = buildSearchUrl(mode);
    const asyncUrl = buildSearchUrl(mode, 'list-async');

    let response = await Api.requestSyncToAsync({
      syncUrl,
      asyncUrl,
      data: request,
      method: 'POST',
      config: {
        pollTokenKey: 'task',
        pollUrlFormat: `${asyncUrl}/{task}`,
      },
    });

    if(response?.data?.data?.location) {
      response = await Api.get(response.data.data.location);
    }

    const {
      data: { detail },
      metadata: { count, count_remain: remain },
    } = response.data;

    detail.withFlattenedUrls = () => {
      return detail.map((item) => {
        const urls = item.ad_url;
        const devices = urls.map((urlDetail) => urlDetail[DEVICE]);
        const pageTitles = urls.map((urlDetail) => urlDetail[TITLE]);
        const pageUrls = urls.map((urlDetail) => urlDetail[LINK_URL]);
        const rate = urls.map((urlDetail) => urlDetail[TRANSITION_RATE]);
        return {
          ...item,
          [DEVICE]: devices,
          [TITLE]: pageTitles,
          [LINK_URL]: pageUrls,
          [TRANSITION_RATE]: rate,
          [PASTE_URL]:
            (item.ad_paste_url && item.ad_paste_url[PASTE_URL]) || null,
          [PASTE_URL_PARALLEL]:
            (item.ad_paste_url && item.ad_paste_url[PASTE_URL_PARALLEL]) ||
            null,
        };
      });
    };

    return {
      detail,
      count,
      remain: isNumber(remain) ? remain : REMAIN_ITEMS_REGISTER,
    };
  };

  const deleteByFilters = async (mode, filters, excludedIds) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/recycle-bin-filters`;
    const request = buildChangeStatusByFiltersRequest(
      filters,
      excludedIds,
      mode
    );

    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
    });

    return response;
  };

  const dropByFilters = async (filters, excludedIds) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/${PATH_BY_MODE[AD_MANAGEMENT_MODE.DELETED]
    }/drop-filters`;
    const request = buildChangeStatusByFiltersRequest(filters, excludedIds);

    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
    });

    return response;
  };

  const restoreByFilters = async (filters, excludedIds) => {
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/${PATH_BY_MODE[AD_MANAGEMENT_MODE.DELETED]
    }/restore-filters`;
    const request = buildChangeStatusByFiltersRequest(filters, excludedIds);

    const response = await Api.requestAsync(url, request, 'PUT', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
    });

    return response;
  };

  const downloadCsv = async (mode, filters, sort) => {
    const parts = [AD_MANAGEMENT_API_ENDPOINT, 'ad', PATH_BY_MODE[mode], 'csv'];
    const url = parts.filter((part) => !!part).join('/');
    const request = buildDownloadCsvRequest({ filters, sort });
    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
      retryIntervalLow: process.env.REACT_APP_ASYNC_INTERVAL_LOW || 1000,
      retryIntervalLowMax: process.env.REACT_APP_ASYNC_INTERVAL_LOW_MAX || 10,
    });
    const { location } = response.data.data;
    if (location) {
      saveAs(location);
    }
  };

  const changeAd = async (data, type, isSelectedAllPages, filters) => {
    if (!data || !type) {
      throw new Error('data and mode should not be empty');
    }
    let request = {};
    if (isSelectedAllPages) {
      request = buildChangeStatusByFiltersRequest(filters, data, type);
    } else {
      request.data = data;
      request.ad_mode = type;
    }

    request.type = type;
    request.isSelectedAllPages = isSelectedAllPages;

    const response = await Api.post(
      `${AD_MANAGEMENT_API_ENDPOINT}/ad/change-ad`,
      request
    );
    const { data: createdData } = response.data;
    return createdData;
  };

  const changeAdByFilters = async (data, type, isSelectedAllPages, filters) => {
    if (!data || !type) {
      throw new Error('data and mode should not be empty');
    }
    const url = `${AD_MANAGEMENT_API_ENDPOINT}/ad-change-by-filters`;
    const request = buildChangeStatusByFiltersRequest(filters, data, type);
    request.isSelectedAllPages = isSelectedAllPages;

    const response = await Api.requestAsync(url, request, 'POST', {
      pollTokenKey: 'task',
      pollUrlFormat: `${url}/{task}`,
    });
    // const response = await Api.post(
    //   `${AD_MANAGEMENT_API_ENDPOINT}/ad/change-ad`,
    //   request
    // );
    const { data: createdData } = response.data;
    return createdData;
  };

  return {
    search,
    getDetail,
    createAd,
    updateAd,
    restoreAds,
    restoreByFilters,
    deleteAds,
    deleteByFilters,
    dropAds,
    dropByFilters,
    getUploadUrl,
    uploadFile,
    validateCsv,
    submitCsv,
    validateCsvDelete,
    submitCsvDelete,
    downloadCsv,
    createDisplayItems,
    fetchDisplayItems,
    changeAd,
    changeAdByFilters,
  };
};

const AdManagementApi = AdManagementApiFactory();
export default AdManagementApi;
