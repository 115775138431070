import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { connect, useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconDropdown from 'views/atoms/dropdown/IconDropdown';
import ReflectionTimeService from 'services/common/reflectiontime/ReflectionTimeService';
import {
  ANALYSIS_SCREEN,
  SITE_CONTENT,
  LTV_ANALYZED_RESULT,
  CV_UNIT_PRICE,
  COST_LINKING,
  MEDIA_SYNC,
  CROSS_DEVICE,
  LOG,
  GOOGLE_SEARCH_CONSOLE,
} from 'services/common/reflectiontime/constants';
import {
  reflectionTimeSelectors,
  reflectionTimeActions,
} from 'store/reflectiontime';
import {
  LTV_ANALYZE,
  MEDIA_SYNC_MANAGEMENT,
  SEARCH_CONSOLE_SETTING,
} from 'services/routes/constants';
import { isValidDate, getPairType } from 'services/utils';
import screenPermissionConfigs from 'domain/permissions/screenPermissionConfigs';
import useCheckPermissions from 'services/custom-hooks/useCheckPermissions';
import useCheckPermissionSearchConsole from 'services/custom-hooks/useCheckPermissionSC';
import {
  SETTING_COST_VIEW_MEDIA_LINK_EDIT,
  AD_ANALYSIS_ITEM_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
  ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
  MEDIA_SYNC_VIEW,
  CROSS_DEVICE_VIEW,
  ALL_LOG_VIEW,
  MEDIA_SYNC_MANAGEMENT_VIEW,
  SC_SETTING_EDIT,
} from 'domain/permissions/contractPermissions';
import { TAB_AD, TAB_ALL } from 'services/consts';
import ltvAnalyzeActions from 'store/ltv/analyze/actions';
import settingsSelector from 'store/settings/selectors';
import TableTooltips from 'services/common/TableTooltips';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import pages from 'services/routes/pages';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

const Reflection = ({ reflectionData, startFetchData }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { checkPermissions } = useCheckPermissions();

  const mappingKey = {
    [ANALYSIS_SCREEN]: 'analysis_screen',
  };

  const logDisplay = checkPermissions('anyOf', [ALL_LOG_VIEW]);

  const ltvAnalyzePermissions = screenPermissionConfigs[LTV_ANALYZE];
  const ltvAnalyzeResultDisplay = checkPermissions('anyOf', [
    ...ltvAnalyzePermissions[TAB_AD],
    ...ltvAnalyzePermissions[TAB_ALL],
  ]);

  const crossDeviceDisplay = checkPermissions('anyOf', [CROSS_DEVICE_VIEW]);
  const {
    isHaveOnlySearchConsolePermission,
  } = useCheckPermissionSearchConsole();

  const {
    isShowCvUnitPrice,
    isShowCostLinking,
    isShowMediaSync,
    isShowSiteContent,
    isShowGoogleSearchConsole,
  } = useMemo(() => {
    return {
      isShowCvUnitPrice: !isEmpty(reflectionData[CV_UNIT_PRICE]),
      isShowCostLinking:
        checkPermissions('anyOf', [SETTING_COST_VIEW_MEDIA_LINK_EDIT]) &&
        !checkPermissions('anyOf', [MEDIA_SYNC_VIEW]) &&
        !isEmpty(reflectionData[COST_LINKING]),
      isShowMediaSync:
        checkPermissions('anyOf', [MEDIA_SYNC_VIEW]) &&
        !isEmpty(reflectionData[MEDIA_SYNC]),
      isShowSiteContent:
        checkPermissions('anyOf', [
          AD_ANALYSIS_ITEM_LOG_VIEW,
          ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
        ]) && Array.isArray(reflectionData[SITE_CONTENT]),
      isShowGoogleSearchConsole:
        // Check permission has SEO Contract to show google search console
        (checkPermissions('anyOf', [
          ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW,
          ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW,
          ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW,
        ]) ||
          isHaveOnlySearchConsolePermission) &&
        !isEmpty(reflectionData[GOOGLE_SEARCH_CONSOLE]),
    };
  }, [checkPermissions, isHaveOnlySearchConsolePermission, reflectionData]);

  // Merge services
  const reportServices = useMemo(() => {
    if (isEmpty(reflectionData)) return [];

    const services = [];

    const analysisScreenData = reflectionData[ANALYSIS_SCREEN];
    if (!isEmpty(analysisScreenData)) {
      analysisScreenData[0].tooltip =
        TableTooltips.toolTipReflectionStatusMainMessage;
      services.push(...analysisScreenData);
    }

    if (!isEmpty(reflectionData[LOG]) && logDisplay) {
      services.push(...reflectionData[LOG]);
    }

    // crossDevice
    const crossDeviceData = reflectionData[CROSS_DEVICE];
    if (!isEmpty(crossDeviceData) && crossDeviceDisplay) {
      crossDeviceData[0].tooltip = TableTooltips.crossDeviceDateMessage;
      services.push(...crossDeviceData);
    }

    const googleSearchConsoleData = reflectionData[GOOGLE_SEARCH_CONSOLE];
    if (isShowGoogleSearchConsole) {
      googleSearchConsoleData[0].tooltip =
        TableTooltips.tooltipGoogleSearchConsoleMessage;
      if (checkPermissions('anyOf', [SC_SETTING_EDIT])) {
        googleSearchConsoleData[0].description = (
          <>
            詳細は
            <Link to={pages[SEARCH_CONSOLE_SETTING].path}>
              Google Search Console連携設定
            </Link>
            よりご確認ください
          </>
        );
      }
      services.push(...googleSearchConsoleData);
    }

    return services;
  }, [
    reflectionData,
    logDisplay,
    crossDeviceDisplay,
    isShowGoogleSearchConsole,
    checkPermissions,
  ]);

  const hasPermissionMediaSyncManagementView = checkPermissions('anyOf', [
    MEDIA_SYNC_MANAGEMENT_VIEW,
  ]);

  // Get Current Page ID
  const screenId = useSelector(settingsSelector.getPage, shallowEqual);

  // LTVForecast Analyzed Status
  useEffect(() => {
    if (screenId !== LTV_ANALYZE && ltvAnalyzeResultDisplay) {
      dispatch(ltvAnalyzeActions.fetchStatus());
    }
  }, [dispatch, screenId, ltvAnalyzeResultDisplay]);

  const isShowLtvForecast = useMemo(() => {
    return (
      ltvAnalyzeResultDisplay && !isEmpty(reflectionData[LTV_ANALYZED_RESULT])
    );
  }, [ltvAnalyzeResultDisplay, reflectionData]);

  // Startup services
  React.useEffect(() => {
    ReflectionTimeService.startRelectionTimeService(startFetchData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dropdown>
      <Dropdown.Toggle as={IconDropdown}>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip className="tooltip-common">反映時間</Tooltip>}
        >
          <div className="d-flex justify-content-center">
            <span className="icon-svg icon-data-update-status icon-svg__w36" />
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>
      <Dropdown.Menu
        alignRight="true"
        variant="custom-view"
        className="dropdown-menu-shadow nav-reflection-time"
      >
        <Dropdown.Header
          as="div"
          bsPrefix="dropdown-header"
          className="d-flex align-items-center"
        >
          <span>{t('topbar.reflection_time.update_status')}</span>
        </Dropdown.Header>

        {reflectionData && reflectionData[ANALYSIS_SCREEN] && (
          <>
            {reportServices
              .filter((item) => isValidDate(item.updated_time, []))
              .map((item) => {
                const keyTranslation = mappingKey[item.service] || item.service;
                return (
                  <Dropdown key={item.service} className="detail-media">
                    <div className="d-flex align-items-center justify-content-between">
                      <span>
                        {t(`topbar.reflection_time.${keyTranslation}`)}
                        {item.tooltip && (
                          <DragonBall variant="text" stayOpen>
                            {item.tooltip}
                          </DragonBall>
                        )}
                      </span>
                      {item.service === 'cross_device' ? (
                        <>
                          <span className="pair-type">
                            {getPairType(item.pair_type)}
                          </span>
                          <span style={{ paddingLeft: 10, paddingRight: 2 }}>
                            {ReflectionTimeService.formatDate(item.to_date)}
                          </span>
                        </>
                      ) : (
                        <span>
                          {ReflectionTimeService.isDateFormat(item.updated_time)
                            ? ReflectionTimeService.formatDate(
                                item.updated_time
                              )
                            : ReflectionTimeService.formatDateTime(
                                item.updated_time
                              )}
                        </span>
                      )}
                    </div>
                    {get(item, 'description', '') && (
                      <span className="detail-notes align-items-center">
                        {get(item, 'description', '')}
                      </span>
                    )}
                  </Dropdown>
                );
              })}
            {(isShowSiteContent ||
              isShowLtvForecast ||
              isShowCvUnitPrice ||
              isShowCostLinking ||
              isShowMediaSync) && (
              <Dropdown.Header>
                <span>
                  {t('topbar.reflection_time.update_status_prev_day')}
                </span>
                <span>{ReflectionTimeService.getPreviousDate()}分</span>
              </Dropdown.Header>
            )}
          </>
        )}
        {isShowSiteContent && (
          <Dropdown className="detail-media d-flex align-items-center justify-content-between">
            <span>{t('topbar.reflection_time.site_content')}</span>
            {reflectionData[SITE_CONTENT][0]?.status === 'completed' && (
              <i className="fas fa-check" />
            )}
            {reflectionData[SITE_CONTENT][0]?.status === 'running' && (
              <span className="status-running">準備中</span>
            )}
          </Dropdown>
        )}
        {isShowLtvForecast && (
          <Dropdown className="detail-media d-flex align-items-center justify-content-between">
            <span>{t('topbar.reflection_time.ltv_analyzed_result')}</span>
            {reflectionData[LTV_ANALYZED_RESULT].status === 'completed' && (
              <i className="fas fa-check" />
            )}
            {reflectionData[LTV_ANALYZED_RESULT].status === 'running' && (
              <span className="status-running">準備中</span>
            )}
          </Dropdown>
        )}
        {isShowCvUnitPrice && (
          <Dropdown className="detail-media d-flex align-items-center justify-content-between">
            <span>{t('topbar.reflection_time.cv_unit_price')}</span>
            {reflectionData[CV_UNIT_PRICE][0].status && (
              <i className="fas fa-check" />
            )}
          </Dropdown>
        )}
        {isShowCostLinking && (
          <Dropdown className="list-media">
            {t('topbar.reflection_time.cost_linking')}
            {reflectionData[COST_LINKING].map(
              (item) =>
                item.active && (
                  <Dropdown
                    key={item.service}
                    className="detail-media d-flex align-items-center justify-content-between"
                  >
                    <span>
                      {t(ReflectionTimeService.getServiceName([item.service]))}
                    </span>
                    {item.status && <i className="fas fa-check" />}
                  </Dropdown>
                )
            )}
          </Dropdown>
        )}
        {isShowMediaSync && (
          <Dropdown className="list-media">
            {t('topbar.reflection_time.media_sync')}
            {reflectionData[MEDIA_SYNC].map(
              (item) =>
                item.active && (
                  <Dropdown
                    key={item.sync_media_id}
                    className="detail-media d-flex align-items-center justify-content-between"
                  >
                    <span>
                      {t(
                        ReflectionTimeService.getMediaName([item.sync_media_id])
                      )}
                    </span>
                    {item.status === 'completed' && (
                      <i className="fas fa-check" />
                    )}
                    {item.status === 'running' && (
                      <span className="status-running">準備中</span>
                    )}
                  </Dropdown>
                )
            )}
            {hasPermissionMediaSyncManagementView && (
              <span className="detail-notes d-flex align-items-center">
                詳細は
                <Link to={pages[MEDIA_SYNC_MANAGEMENT].path}>
                  媒体シンク設定画面
                </Link>
                よりご確認ください
              </span>
            )}
          </Dropdown>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

Reflection.propTypes = {
  reflectionData: PropTypes.shape({}),
  startFetchData: PropTypes.func.isRequired,
};

Reflection.defaultProps = {
  reflectionData: {},
};

const mapStateToProps = (state) => ({
  reflectionData: reflectionTimeSelectors.getReflectionTimeData(state),
});

const mapDispatchToProps = (dispatch) => ({
  startFetchData: () => dispatch(reflectionTimeActions.fetchReflectionStart()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Reflection));
